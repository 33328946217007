<template>
	<CRow class="mb-3">
		<CCol md="7">
			<CRow>
				<CCol md="2" class="typo-body-2">
					{{ id }}
				</CCol>
				<CCol md="10">
					<template v-if="isPromotionAvailable">
						<p class="typo-body-2 my-0 p-0">{{ name }}</p>
						<span class="typo-caption color-info my-0 p-0">{{ couponCodesText }}</span>
					</template>
					<p v-else class="typo-body-2 my-0 p-0 color-black-45">This promotion has been removed.</p>
				</CCol>
			</CRow>
		</CCol>
		<CCol md="5">
			<CRow>
				<CCol md="4" class="p-0 pr-1">
					<CBadge
						v-if="isPromotionAvailable"
						class="badge-status align-top"
						:color="status.value.toLowerCase()"
					>
						{{ status.label }}
					</CBadge>
				</CCol>
				<CCol md="4" class="p-0 pr-1">
					<CBadge
						v-if="isPromotionAvailable"
						class="badge-status align-top"
						:color="periodStatus.color"
					>
						{{ periodStatus.title }}
					</CBadge>
				</CCol>
				<CCol md="4" class="d-flex px-0">
					<router-link
						v-if="isPromotionAvailable"
						:to="{ name: 'PromotionEdit', params: { id } }"
						target="_blank"
						class="typo-body-2 color-hypertext align-self-start text-nowrap mr-1"
					>
						View detail
					</router-link>
					<CButton
						v-if="isShowRemove"
						class="transparent ml-auto py-0 px-1"
						data-test-id="button-remove"
						@click="handleRemove"
					>
						<CIcon name="cil-trash" class="color-black-45" />
					</CButton>
				</CCol>
			</CRow>
		</CCol>
		<CCol v-if="isPromotionAvailable && showEmailTemplate" md="12">
			<div class="email-template-box d-flex align-items-center">
				<label class="typo-body-2 m-0">Email template ID</label>
				<CInput
					:value="emailTemplateId"
					class="ml-2 mb-0 flex-grow-1"
					placeholder=""
					text-align="left"
					@input="handleEmailTemplateIdChange"
				/>
			</div>
		</CCol>
	</CRow>
</template>

<script>
export default {
	name: 'CouponListItem',
	props: {
		id: {
			type: [String, Number],
			default: null,
		},
		couponCodesText: {
			type: String,
			default: null,
		},
		name: {
			type: String,
			default: null,
		},
		status: {
			type: Object,
			default: null,
		},
		startAt: {
			type: String,
			default: null,
		},
		endAt: {
			type: String,
			default: null,
		},
		periodStatus: {
			type: Object,
			default: null,
		},
		showEmailTemplate: {
			type: Boolean,
			default: false,
		},
		emailTemplateId: {
			type: String,
			default: null,
		},
	},
	computed: {
		isShowRemove() {
			return !!this.$listeners.onRemove;
		},
		isPromotionAvailable() {
			return this.status;
		},
	},
	methods: {
		handleRemove() {
			this.$emit('onRemove');
		},
		handleEmailTemplateIdChange(value) {
			this.$emit('update:emailTemplateId', value);
		},
	},
};
</script>

<style lang="scss" scoped>
	.email-template-box {
		background-color: $color-gray-100;
		padding: rem(16);
		margin-top: rem(16);
		border-radius: rem(4);
	}
</style>
