<template>
	<div>
		<CRow>
			<CCol md="12">
				<h2 class="typo-h4 position-relative">
					Purchase history condition
				</h2>
			</CCol>
		</CRow>

		<CRow class="mt-4">
			<CCol md="8">
				<label>Condition type</label>
			</CCol>
			<CCol md="4" class="text-right">
				<CInputRadioGroup
					:options="CONDITION_TYPE_OPTIONS"
					:custom="true"
					:inline="true"
					:checked="conditionType"
					size="sm"
					class="radio-group"
					name="purchase-history-condition-type"
					@update:checked="handleChangeConditionType"
				/>
			</CCol>
		</CRow>

		<CRow class="mt-3">
			<CCol md="4" class="mt-3">
				<label class="pt-3">Last order date</label>
			</CCol>
			<CCol md="4">
				<BaseInputDate
					v-model="lastOrderStartDate"
					label="From"
					placeholder="Select date"
					@input="handleLastOrderDateStartDateChange"
				>
					<template #append-content>
						<CIcon class="cil-calendar" name="cil-calendar" />
					</template>
				</BaseInputDate>
			</CCol>
			<CCol md="4">
				<BaseInputDate
					v-model="$v.lastOrderEndDate.$model"
					:disabled="!lastOrderStartDate"
					:is-valid="!$v.lastOrderEndDate.$error"
					:min-date="lastOrderStartDate"
					label="To"
					invalid-feedback="Date is required"
					placeholder="Select date"
					@input="handleLastOrderDateEndDateChange"
				>
					<template #append-content>
						<CIcon class="cil-calendar" name="cil-calendar" />
					</template>
				</BaseInputDate>
			</CCol>
		</CRow>

		<CRow class="mt-4">
			<CCol md="8">
				<label class="pt-3">Complete order</label>
			</CCol>
			<CCol md="4">
				<BaseInputNumber
					v-model="completeOrderQuantity"
					append-text="Qty."
					text-align="left"
					placeholder="0"
					@input="handleCompleteOrderQuantityChange"
				/>
			</CCol>
		</CRow>

		<CRow class="mt-3">
			<CCol md="4">
				<label class="pt-3 mt-3">Total sales of</label>
			</CCol>
			<CCol md="4">
				<BaseDropDown
					:value="salesType"
					:options="saleTypeOptions"
					:searchable="false"
					:allow-empty="false"
					class="select-custom"
					label="label"
					track-by="value"
					label-drop-down="Sales type"
					@input="handleSalesTypeChange"
				/>
			</CCol>
			<CCol md="4">
				<div class="condition-value">
					<label>Value</label>
					<div v-if="!salesType || !salesType.value" class="empty-value">
						<span>Select value</span>
					</div>
					<div v-else>
						<CButton
							type="button"
							class="btn mr-3 w-100"
							color="secondary"
							@click="handleSalesValuesClick"
						>
							{{ $v.salesValues.$model ? 'Edit' : 'Add' }} {{ CONDITION_KEY_LABELS[CONDITION_KEYS[salesType.value]] }}
						</CButton>
						<p class="mt-1">{{ summaryOfSaleValue }}</p>
					</div>
					<div
						v-if="salesType && $v.salesValues.$error"
						class="invalid-feedback d-block"
					>
						{{ $t('global.error.required') }}
					</div>
				</div>
			</CCol>
		</CRow>

		<CRow class="mt-3">
			<CCol md="8" />
			<CCol md="4">
				<BaseInputNumber
					v-model="salesQuantity"
					:disabled="!salesType || !salesType.value"
					:label="salesQuantityLabel"
					:is-valid="!$v.salesQuantity.$error"
					:invalid-feedback="$t('global.error.required')"
					append-text="Qty."
					text-align="left"
					placeholder="Type sales quantity"
					@input="handleSalesQuantityChange"
				/>
			</CCol>
		</CRow>

		<ModalSkuList
			ref="modal-sku"
			title="Select SKUs"
			:sku-list="selectedValues"
			@onConfirm="handleSalesValuesListChange"
		/>

		<ModalBrand
			ref="modal-brand"
			title="Select Brands"
			:list="selectedValues"
			@onConfirm="handleSalesValuesListChange"
		/>

		<ModalCategory
			ref="modal-category"
			title="Select categories"
			:selected-ids="selectedValues"
			@onSubmit="handleSalesValuesListChange"
		/>
	</div>
</template>

<script>
import dayjs from 'dayjs';
import { requiredIf } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { mapActions } from 'vuex';

import ModalSkuList from '@/components/ModalSkuList.vue';
import ModalBrand from '@/components/ModalBrand.vue';
import ModalCategory from '@/components/ModalCategory.vue';

import { numberFormat, convertDateTimeToUTC, datetimeFormat } from '../assets/js/helpers';

import {
	CONDITION_TYPE_OPTIONS,
	CONDITION_TYPES,
	CONDITION_KEYS,
	CONDITION_KEY_LABELS,
	CONDITION_KEY_OPTIONS,
	PURCHASE_HISTORY_CONDITION_TYPES,
} from '../enums/promotions';

export default {
	name: 'PromotionPurchaseHistoryCondition',
	validations() {
		return {
			completeOrderQuantity: { },
			lastOrderEndDate: {
				required: requiredIf((vm) => vm.lastOrderStartDate),
			},
			salesValues: {
				required: requiredIf((vm) => vm.salesType && vm.salesType.value),
			},
			salesQuantity: {
				required: requiredIf((vm) => vm.salesType && vm.salesType.value),
			},
		};
	},
	components: {
		ModalSkuList,
		ModalBrand,
		ModalCategory,
	},
	mixins: [validationMixin],
	props: {
		defaultData: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		const { type = CONDITION_TYPES.ALL, param } = this.defaultData || {};
		let completeOrderQuantity = null;
		let lastOrderStartDate = null;
		let lastOrderEndDate = null;
		let salesType = null;
		let salesValues = [];
		let salesQuantity = null;
		if (param && param.conditions) {
			const completeOrderCondition = param.conditions.find((cond) => cond.type === PURCHASE_HISTORY_CONDITION_TYPES.COMPLETE_ORDER);
			const lastOrderDateCondition = param.conditions.find((cond) => cond.type === PURCHASE_HISTORY_CONDITION_TYPES.LAST_ORDER_DATE);
			const totalSaleCondition = param.conditions.find((cond) => cond.type === PURCHASE_HISTORY_CONDITION_TYPES.TOTAL_SALE);
			completeOrderQuantity = completeOrderCondition?.param?.value || null;
			lastOrderStartDate = lastOrderDateCondition?.param?.from ?? null;
			lastOrderStartDate = lastOrderStartDate ? new Date(datetimeFormat(lastOrderStartDate, 'YYYY-MM-DD')) : null; // convert to be type of Date
			lastOrderEndDate = lastOrderDateCondition?.param?.to ?? null;
			lastOrderEndDate = lastOrderEndDate ? new Date(datetimeFormat(lastOrderEndDate, 'YYYY-MM-DD')) : null; // convert to be type of Date
			salesType = totalSaleCondition?.param?.type || null;
			salesType = CONDITION_KEY_OPTIONS.find((option) => option.value === salesType) || null;
			salesValues = totalSaleCondition?.param?.targets || [];
			if (salesType && salesType.value && [CONDITION_KEYS.BRAND, CONDITION_KEYS.CATEGORY].includes(salesType.value)) {
				salesValues = salesValues.map((id) => ({ id })); // Transform to be array of Object
			}
			salesQuantity = totalSaleCondition?.param?.quantity || null;
		}

		return {
			CONDITION_TYPES,
			CONDITION_KEYS,
			CONDITION_KEY_LABELS,
			CONDITION_TYPE_OPTIONS,
			conditionType: type,
			completeOrderQuantity,
			lastOrderStartDate,
			lastOrderEndDate,
			salesType,
			salesValues,
			salesQuantity,
		};
	},
	computed: {
		salesQuantityLabel() {
			return `Sales quantity${this.salesType && this.salesType.value ? '*' : ''}`;
		},
		saleTypeOptions() {
			return [
				{ value: null, label: 'Select rule' },
				...CONDITION_KEY_OPTIONS,
			];
		},
		summaryOfSaleValue() {
			if ((!this.salesType || !this.salesType.value) && !this.salesValues) {
				return '';
			}

			// No selected any value
			if (this.salesType && this.salesType.value && !this.salesValues) {
				return `No ${CONDITION_KEY_LABELS[CONDITION_KEYS[this.salesType.value]]} selected`;
			}

			// Fully summary
			if (Array.isArray(this.salesValues)) {
				return `${numberFormat(this.salesValues.length)} ${CONDITION_KEY_LABELS[CONDITION_KEYS[this.salesType.value]]}(s) selected`;
			}

			// Implicit value
			return this.salesValues;
		},
		selectedValues() {
			let values = [];
			const conditionKey = this.salesType?.value;
			switch (conditionKey) {
				case CONDITION_KEYS.SKU:
					values = this.salesValues || [];
					break;
				case CONDITION_KEYS.BRAND:
					values = (this.salesValues || []).map(({ id, name }) => ({ id, name }));
					break;
				case CONDITION_KEYS.CATEGORY:
					values = (this.salesValues || []).map(({ id }) => (id));
					break;
				default: break;
			}
			return values;
		},
	},
	methods: {
		...mapActions({
			preSelectCategories: 'categorySelector/preSelectCategories',
		}),
		handleChangeConditionType(value) {
			this.conditionType = value;
			this.handleUpdateValue();
		},
		handleCompleteOrderQuantityChange() {
			this.handleUpdateValue();
		},
		handleLastOrderDateStartDateChange(value) {
			if (!value) {
				this.lastOrderEndDate = null;
			} else if (!this.lastOrderEndDate || dayjs(value).isAfter(dayjs(this.lastOrderEndDate))) {
				this.lastOrderEndDate = value;
			}

			this.handleUpdateValue();
		},
		handleLastOrderDateEndDateChange() {
			this.handleUpdateValue();
		},
		handleSalesTypeChange(value) {
			this.salesType = value;
			this.salesValues = null;
			this.salesQuantity = null;

			this.handleUpdateValue();
		},
		async handleSalesValuesClick(event) {
			if (!event) { return; }

			const conditionKey = this.salesType?.value;
			switch (conditionKey) {
				case CONDITION_KEYS.SKU:
					this.$refs['modal-sku'].open();
					break;
				case CONDITION_KEYS.BRAND:
					this.$refs['modal-brand'].open();
					break;
				case CONDITION_KEYS.CATEGORY:
					this.preSelectCategories(this.selectedValues);
					this.$refs['modal-category'].open();
					break;
				default: break;
			}
		},
		handleSalesValuesListChange(list) {
			let values = null;
			const conditionKey = this.salesType?.value;
			switch (conditionKey) {
				case CONDITION_KEYS.SKU:
					values = list;
					break;
				case CONDITION_KEYS.BRAND:
					values = list.map((item) => ({ id: item.id, name: item.name }));
					break;
				case CONDITION_KEYS.CATEGORY:
					values = list.map((item) => ({ id: item.id, name: item.name }));
					break;
				default: break;
			}

			this.salesValues = values;

			this.handleUpdateValue();
		},
		handleSalesQuantityChange() {
			this.handleUpdateValue();
		},
		handleUpdateValue() {
			this.$v.$touch();

			const isValid = !this.$v.$invalid;

			const conditions = [];

			if (isValid) {
				// Complete Order
				if (this.completeOrderQuantity > 0) {
					conditions.push({
						type: PURCHASE_HISTORY_CONDITION_TYPES.COMPLETE_ORDER,
						param: {
							value: Number(this.completeOrderQuantity),
						},
					});
				}

				// Last order date
				if (this.lastOrderStartDate > 0) {
					conditions.push({
						type: PURCHASE_HISTORY_CONDITION_TYPES.LAST_ORDER_DATE,
						param: {
							from: convertDateTimeToUTC(this.lastOrderStartDate),
							to: this.lastOrderEndDate ? convertDateTimeToUTC(this.lastOrderEndDate, '23:59:59') : null,
						},
					});
				}

				// Total sales
				if (this.salesType && this.salesType.value) {
					const salesType = this.salesType.value;
					let targets = this.salesValues;
					if ([CONDITION_KEYS.BRAND, CONDITION_KEYS.CATEGORY].includes(salesType)) {
						targets = this.salesValues.map(({ id }) => (id));
					}

					conditions.push({
						type: PURCHASE_HISTORY_CONDITION_TYPES.TOTAL_SALE,
						param: {
							type: salesType,
							targets,
							quantity: Number(this.salesQuantity),
						},
					});
				}
			}

			this.$emit(
				'onUpdate',
				{
					valid: isValid,
					data: conditions.length > 0
						? {
							type: this.conditionType,
							param: {
								conditions,
							},
						}
						: null,
				},
			);
		},
	},
};
</script>

<style lang="scss" scoped>
	.condition-value {
		span {
			line-height: rem(34);
		}

		.empty-value {
			height: rem(34);
			padding: 0 rem(12);
			color: $color-black-25;
			background-color: $color-gray-200;
			border-radius: rem(4);
		}

		.is-invalid {
			border: 1px solid $color-alert;
			border-radius: rem(4);
		}
	}
</style>