<template>
	<div class="d-flex alert alert-warning" role="alert">
		<CIcon data-test-id="icon" class="icon-info" name="cil-info" />
		<p data-test-id="label" class="typo-body-2 mb-0 align-self-center">{{ description }}</p>
	</div>
</template>

<script>
export default {
	name: 'CardDescriptionInfo',

	props: {
		description: {
			type: String,
			default: '',
		},
	},
};
</script>

<style lang="scss" scoped>
	.alert {
		padding: rem(16);

		// .alert-warning
		&-warning {
			background-color: $color-info-10;
			border-color: $color-info-10;
		}

		.icon-info {
			color: $color-info;
			height: rem(24);
			width: rem(24);

			margin-right: rem(16);
		}
	}
</style>
