<template>
	<div>
		<CModal
			:show.sync="isShow"
			:close-on-backdrop="false"
			centered
		>
			<template #header-wrapper>
				<ModalHeaderWrapper
					title="Select bundle SKUs"
					@onClose="close"
				/>
			</template>
			<template #default>
				<CRow>
					<CCol>
						<FormGetProductBySKU
							ref="form-sku"
							:description="description"
							:sku-list="skuList"
							:disabled="reachMaximum"
							show-quantity
							@onGetProduct="handleGet"
							@onRemove="handleRemove"
						>
							<template #no-item>
								<div
									class="no-item d-flex text-center"
									data-test-id="no-item"
								>
									<div class="d-block">
										<div class="no-sku font-weight-bolder color-black-45">
											No SKU yet
										</div>
										<div class="typo-body-2 color-black-45">
											Add "SKU number" in order to set bundle.
										</div>
									</div>
								</div>
							</template>
						</FormGetProductBySKU>
					</CCol>
				</CRow>
			</template>
			<template #footer>
				<CButton
					data-test-id="sku-cancel"
					color="tertiary"
					class="transparent mr-3"
					@click="handleCancel"
				>
					Cancel
				</CButton>
				<CButton
					:disabled="!isValid"
					data-test-id="sku-done"
					color="primary"
					class="transparent"
					@click="handleSubmit"
				>
					Done
				</CButton>
			</template>
		</CModal>

		<BaseModalConfirmDelete
			ref="modal-confirm"
			title="Leave without save?"
			description="All recently added SKUs will be discarded from SKU list"
			class="modal-on-modal"
			delete-button-text="Leave without save"
			@onSuccess="handleReset"
		/>
	</div>
</template>


<script>
import { mapActions } from 'vuex';
import ModalHeaderWrapper from './ModalHeaderWrapper.vue';
import FormGetProductBySKU from './FormGetProductBySKU.vue';

export default {
	name: 'ModalBundleSKU',
	components: {
		ModalHeaderWrapper,
		FormGetProductBySKU,
	},
	props: {
		products: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			minimumSKUs: 2,
			maximumSKUs: 10,
			isShow: false,
			skuList: [],
		};
	},
	computed: {
		description() {
			return `Select al least ${this.minimumSKUs} SKUs for bundle product. Limit ${this.maximumSKUs} SKUs.`;
		},
		isValid() {
			return this.reachMinimum && this.hasQuantity && !this.reachMaximum;
		},
		reachMinimum() {
			// At least x skus
			return this.skuList.length >= this.minimumSKUs;
		},
		reachMaximum() {
			// More than maximum
			return this.skuList.length > this.maximumSKUs;
		},
		hasQuantity() {
			// each data has a quantity
			return !this.skuList.some((item) => !item.quantity || item.quantity < 1);
		},
	},
	methods: {
		...mapActions({
			getProductBySKU: 'shippings/getProductBySKU',
			removeExcludeSKU: 'shippings/removeExcludeSKU',
		}),
		open() {
			this.skuList = [...this.products];
			this.isShow = true;
		},
		close() {
			this.isShow = false;
			this.$refs['form-sku'].resetState();
		},
		handleGet(products) {
			this.skuList = products;
		},
		handleRemove(product) {
			this.skuList = this.skuList.filter((item) => item.sku !== product.sku);
		},
		handleSubmit() {
			this.$emit('update:skuList', this.skuList);
			this.close();
		},
		handleCancel() {
			this.$refs['modal-confirm'].open();
		},
		handleReset() {
			this.close();
		},
	},
};
</script>

<style lang="scss" scoped>
	.no-item {
		min-height: rem(200);
		justify-content: center;
		align-items: center;
	}

	::v-deep .list-item {
		min-height: rem(200);
		padding: 0;
		margin: 0;
	}
</style>
