<template>
	<div data-test-id="sku-modal">
		<CForm class="d-flex align-items-start mb-3" @submit.prevent="handleSubmit">
			<div class="flex-fill">
				<CInput
					v-model.trim="inputSKU"
					class="form-sku-input"
					:disabled="disabled"
					:is-valid="!isNotFound && !isDuplicated && isValid && !errorMsg && null"
					:label="label"
					:placeholder="placeholder"
					add-input-classes="form-get-sku-input"
					data-test-id="input-sku"
					@input="handleChange"
				>
					<template #invalid-feedback>
						<div
							v-if="isNotFound"
							class="invalid-feedback"
							data-test-id="error-item-not-found"
						>
							We can't find this SKU in the system
						</div>
						<div
							v-else-if="isDuplicated"
							class="invalid-feedback"
							data-test-id="error-item-duplicated"
						>
							This SKU is already added, please try another.
						</div>
						<div
							v-else-if="!isValid"
							class="invalid-feedback"
							data-test-id="error-item-invalid"
						>
							{{ invalidFeedback }}
						</div>
						<div
							v-else
							class="invalid-feedback"
							data-test-id="error-item-has-been-product-compare"
						>
							{{ errorMsg }}
						</div>
					</template>
				</CInput>
				<div v-if="description" class="typo-caption color-black-45 mb-2">
					{{ description }}
				</div>
			</div>
			<CButton
				:disabled="disabled || isSubmitting"
				type="submit"
				class="btn-add ml-2"
				color="secondary"
				data-test-id="add-sku-btn"
			>
				Add SKU
			</CButton>
		</CForm>
		<slot
			v-if="!skuList.length"
			name="no-item"
		>
		</slot>
		<ul
			v-else
			class="list-item"
			:class="{ 'show-border': hasBorder }"
		>
			<SKUListItem
				v-for="item in skuList"
				:key="item.id"
				:thumbnail="item.thumbnail"
				:name="item.name"
				:sku="item.sku"
				:show-quantity="showQuantity"
				:quantity="item.quantity"
				@onChangeQty="handleChangeQty(item.sku, $event)"
				@onRemove="handleRemove(item.sku)"
			/>
		</ul>
	</div>
</template>

<script>
import { getProductBySKUAPI } from '../services/api/sku.api';
import { transformedProduct } from '../assets/js/transform/products';
import SKUListItem from './SKUListItem.vue';

export default {
	name: 'FormGetProductBySKU',
	components: {
		SKUListItem,
	},
	props: {
		label: {
			type: String,
			default: null,
		},
		originalSkuList: {
			type: Array,
			default: () => [],
		},
		skuList: {
			type: Array,
			default: () => [],
		},
		hasBorder: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		isValid: {
			type: Boolean,
			default: true,
		},
		invalidFeedback: {
			type: String,
			default: null,
		},
		placeholder: {
			type: String,
			default: 'Paste or type SKU number here',
		},
		showQuantity: {
			type: Boolean,
			default: false,
		},
		description: {
			type: String,
			default: null,
		},
		isValidateProductCompare: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isSubmitting: false,
			isNotFound: false,
			isDuplicated: false,
			inputSKU: '',
			errorMsg: '',
		};
	},
	methods: {
		resetState() {
			this.inputSKU = '';
			this.errorMsg = '';
			this.isNotFound = false;
			this.isDuplicated = false;
		},
		handleChange() {
			this.isNotFound = false;
			this.isDuplicated = false;
		},
		handleChangeQty(sku, value) {
			this.resetState();
			const newSkuList = this.skuList.map((product) => {
				if (product.sku !== sku) {
					return product;
				}

				return { ...product, quantity: value };
			});

			this.$emit('onGetProduct', newSkuList);
		},
		handleRemove(sku) {
			this.resetState();
			const foundProduct = this.skuList.find((product) => product.sku === sku);
			this.$emit('onRemove', foundProduct);
		},
		handleReset() {
			this.resetState();
			this.$emit('update:skuList', this.originalSkuList);
			this.close();
		},
		async handleSubmit() {
			this.errorMsg = '';
			const exists = this.skuList.find((product) => product.sku.toUpperCase() === this.inputSKU.toUpperCase());
			if (exists) {
				this.isDuplicated = true;
				return;
			}

			this.isNotFound = false;
			this.isSubmitting = true;

			try {
				const params = {
					q: this.inputSKU,
				};
				if (this.isValidateProductCompare) {
					params.validate_product_compare = 1;
				}
				const { data } = await getProductBySKUAPI(params);
				this.$emit('onGetProduct', [
					transformedProduct(data.data),
					...this.skuList,
				]);
				this.resetState();
			} catch (error) {
				/* eslint-disable */
				if (error?.response?.status == 409) {
					this.errorMsg = error.response.data.message;
				} else {
					this.isNotFound = true;
				}
			} finally {
				this.isSubmitting = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	::v-deep .input-group-text {
		padding-right: 0;
	}

	::v-deep .form-get-sku-input {
		margin-right: rem(100);
	}

	.form-sku-input {
		margin-bottom: rem(8);
	}

	.btn-add {
		min-width: rem(84);
	}

	.list-item {
		padding: rem(20) rem(16) 0 rem(16);
		max-height: rem(320);
		overflow-x: hidden;
		overflow-y: auto;
	}

	.show-border {
		border: solid 1px $color-gray-400;
		border-radius: rem(4);
	}
</style>
