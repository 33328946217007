<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<div v-else class="mt-4">
		<div class="tabs-container-large mb-5 pb-5">
			<div v-if="steps" class="tabs-wrapper">
				<CTabs
					:active-tab="activeTab"
					@update:activeTab="handleUpdateTab"
				>
					<CTab
						v-for="(step, key) in steps"
						:key="key"
						:title="`Step ${key}: ${step.title}`"
						:disabled="step.disabled"
					>
						<div class="main-wrapper">
							<component
								:is="step.component"
								:default-data="step.formData"
								:promotion-type="promotionType"
								@update:is-valid="handleValidChange(key, $event)"
							/>
						</div>
					</CTab>
				</CTabs>
			</div>
			<div class="navigation-panel">
				<div class="main-wrapper">
					<CRow>
						<CCol
							lg="12"
							class="d-flex justify-content-end align-items-center"
						>
							<span class="mr-4">Step {{ currentStep }}/{{ totalStep }}</span>
							<CButton
								color="tertiary"
								class="transparent mr-3"
								@click.prevent="$router.push({ name: 'PromotionList'})"
							>
								Cancel
							</CButton>
							<CButton
								type="button"
								class="transparent"
								color="primary"
								data-test-id="button"
								:disabled="!isEnableNextStep || isSubmitting"
								@click="handleClickNextStep"
							>
								{{ confirmText }}
							</CButton>
						</CCol>
					</CRow>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import PromotionStepGeneralInfo from '@/components/PromotionStepGeneralInfo.vue';
import PromotionStepCondition from '@/components/PromotionStepCondition.vue';
import PromotionStepReward from '@/components/PromotionStepReward.vue';
import PromotionStepDisplaying from '@/components/PromotionStepDisplaying.vue';
import PromotionStepECoupon from '@/components/PromotionStepECoupon.vue';
import { PROMOTION_LABEL_TITLE_TABS } from '../enums/promotionLabels';
import { PROMOTION_TYPES, PROMOTION_TYPE_LABELS } from '../enums/promotions';
import {
	transformedPromotionInfoToDataMultipleStep,
	transformedPromotionDataToAPI,
} from '../assets/js/transform/promotions';

import { getECouponCodePattern } from '../assets/js/helpers';

export default {
	name: 'PromotionCreate',

	data() {
		return {
			promotionType: PROMOTION_TYPES.GENERAL,
			currentStep: 1,
			totalStep: 0,
			steps: null,
			isLoading: true,
			isSubmitting: false,
		};
	},

	computed: {
		...mapState('promotions', {
			create: 'create',
			edit: 'edit',
		}),

		activeTab() {
			return this.currentStep - 1;
		},

		isEnableNextStep() {
			const nextStep = this.currentStep + 1;
			if (this.currentStep === this.totalStep) {
				return this.steps[this.currentStep].valid;
			}

			return !this.steps[nextStep].disabled && this.steps[this.currentStep].valid;
		},

		isTypeBundle() {
			return this.promotionType === PROMOTION_TYPES.BUNDLE;
		},

		isTypeTier() {
			return this.promotionType === PROMOTION_TYPES.TIER;
		},

		isTypeECoupon() {
			return this.promotionType === PROMOTION_TYPES.COUPON;
		},

		confirmText() {
			if (this.isSubmitting) {
				return 'Creating...';
			}

			return this.currentStep === this.totalStep ? 'Create' : 'Next';
		},
	},

	async created() {
		const { ref: promotionId = null, type = '' } = this.$route.query;
		this.promotionType = (type || PROMOTION_TYPES.GENERAL).toUpperCase(); // Force uppercase on query parameter

		this.initialStep();

		if (promotionId) {
			await this.getPromotion(promotionId);
			await this.extractDataToStep();
		} else {
			this.isLoading = false;
		}

		this.setPageTitle({
			title: `Create ${PROMOTION_TYPE_LABELS[this.promotionType]} promotion`,
			isShow: true,
		});
	},

	methods: {
		...mapActions({
			setPageTitle: 'pageTitle/setPageTitle',
			getPromotion: 'promotions/getPromotion',
			createPromotion: 'promotions/createPromotion',
		}),
		initialStep() {
			if (this.promotionType === PROMOTION_TYPES.COUPON) {
				this.steps = {
					1: {
						title: PROMOTION_LABEL_TITLE_TABS.GENERAL_INFO,
						valid: false,
						disabled: false,
						component: PromotionStepGeneralInfo,
						formData: null,
					},
					2: {
						title: PROMOTION_LABEL_TITLE_TABS.CONDITION,
						valid: true,
						disabled: true,
						component: PromotionStepCondition,
						formData: {},
					},
					3: {
						title: PROMOTION_LABEL_TITLE_TABS.COUPON,
						valid: false,
						disabled: true,
						component: PromotionStepECoupon,
						formData: null,
					},
					4: {
						title: PROMOTION_LABEL_TITLE_TABS.REWARD,
						valid: false,
						disabled: true,
						component: PromotionStepReward,
						formData: null,
					},
					5: {
						title: PROMOTION_LABEL_TITLE_TABS.DISPLAYING,
						valid: false,
						disabled: true,
						component: PromotionStepDisplaying,
						formData: null,
					},
				};
			} else {
				this.steps = {
					1: {
						title: PROMOTION_LABEL_TITLE_TABS.GENERAL_INFO,
						valid: false,
						disabled: false,
						component: PromotionStepGeneralInfo,
						formData: null,
					},
					2: {
						title: PROMOTION_LABEL_TITLE_TABS.CONDITION,
						valid: true,
						disabled: true,
						component: PromotionStepCondition,
						formData: {},
					},
					3: {
						title: PROMOTION_LABEL_TITLE_TABS.REWARD,
						valid: false,
						disabled: true,
						component: PromotionStepReward,
						formData: null,
					},
					4: {
						title: PROMOTION_LABEL_TITLE_TABS.DISPLAYING,
						valid: false,
						disabled: true,
						component: PromotionStepDisplaying,
						formData: null,
					},
				};
			}

			this.totalStep = Object.keys(this.steps).length;
		},
		async handleClickNextStep() {
			if (this.currentStep === this.totalStep) {
				await this.submitForm();

				return;
			}

			// Handle tab's disabled status
			if (this.isEnableNextStep) {
				this.currentStep += 1;
			}
		},

		handleUpdateTab(tabIndex) {
			this.currentStep = tabIndex + 1;
		},

		handleValidChange(key, value) {
			if (!key) {
				return;
			}
			const { valid, data: formData } = value;

			this.$set(this.steps, key, { ...this.steps[key], valid, formData });
			// Toggle disabled status for next tab
			const nextIndex = Number(key) + 1;
			if (nextIndex <= this.totalStep) {
				this.$set(this.steps, (nextIndex), { ...this.steps[nextIndex], disabled: !valid });
			}

			// Check disabled status for the rest
			// E-Coupon
			// Tier Condition
			for (let index = nextIndex; index <= this.totalStep; index++) {
				if (!valid) {
					// if next tab (n+1) is disabled, the rest must be disabled too.
					this.$set(this.steps, (index), { ...this.steps[index], disabled: true });
				} else if (this.steps[index].valid) {
					// if next tab (n+1) is enabled and already valid, The next two tab (n+2) should be enabled too.
					const nextTwoIndex = index + 1;
					if (nextTwoIndex <= this.totalStep) {
						this.$set(this.steps, (nextTwoIndex), { ...this.steps[nextTwoIndex], disabled: false });
					}
				}

				// Bundle Promotion
				if (this.isTypeBundle) {
					if (Number(key) === 2) {
						const { productBundleCondition } = this.steps[2].formData;

						this.$set(this.steps, (3), {
							...this.steps[3],
							formData: {
								...this.steps[3].formData,
								productBundleCondition,
							},
						});
					}
				}

				// E-Coupon promotion
				// Use couponCodes to display badge UI
				if (this.isTypeECoupon) {
					if (Number(key) === 3) {
						const {
							prefix = '',
							length = 0,
							couponCodes,
						} = this.steps[3].formData || {};

						const couponQuantity = (couponCodes || []).length;
						const couponCodePattern = getECouponCodePattern(prefix, length, couponCodes);

						this.$set(this.steps, (5), {
							...this.steps[5],
							formData: {
								...this.steps[5].formData,
								couponQuantity,
								couponCodePattern,
							},
						});
					}
				}

				// Tier Promotion
				if (this.isTypeTier) {
					if (Number(key) === 2) {
						const { tierCondition } = this.steps[2].formData || {};
						const { tierCondition: tierConditionNextStep } = this.steps[index].formData || {};

						const mergeTierConditions = tierCondition.map((cond, condIndex) => {
							if (tierConditionNextStep && tierConditionNextStep[condIndex]) {
								return {
									...cond,
									...tierConditionNextStep[condIndex],
								};
							}

							return cond;
						});

						this.$set(this.steps, (index), {
							...this.steps[index],
							formData: {
								...this.steps[index].formData,
								tierCondition: mergeTierConditions,
							},
						});
					}
				}
			}
		},

		async submitForm() {
			this.isSubmitting = true;

			const postData = transformedPromotionDataToAPI({ type: this.promotionType, steps: this.steps });
			try {
				await this.createPromotion(postData);

				this.$router.push({ name: 'PromotionList' });
			} catch {
				// do nothing
			} finally {
				this.isSubmitting = false;
			}
		},

		async extractDataToStep() {
			if (!this.edit.data) { return; }

			const promotionData = await transformedPromotionInfoToDataMultipleStep(this.edit.data);
			if (promotionData) {
				this.promotionType = promotionData.promotionType;

				// Re-initial step
				this.initialStep();

				this.steps[1].formData = promotionData.steps[1].formData;
				this.steps[2].formData = promotionData.steps[2].formData;
				this.steps[3].formData = promotionData.steps[3].formData;
				this.steps[4].formData = promotionData.steps[4].formData;
				if (this.steps[5]) {
					const {
						prefix = '',
						length = 0,
						couponCodes = [],
					} = promotionData.steps[3].formData;
					const { formData } = promotionData.steps[5];

					const couponQuantity = (couponCodes || []).length;
					const couponCodePattern = getECouponCodePattern(prefix, length, couponCodes);

					const couponFormData = {
						...formData,
						couponQuantity,
						couponCodePattern,
					};
					this.steps[5].formData = couponFormData;
				}

				// Override value to enable UI
				this.steps[1].formData.id = null;
				this.steps[1].formData.periodStatus = null;
				this.steps[1].formData.startDate = null;
				this.steps[1].formData.startTime = undefined;
				this.steps[1].formData.endDate = null;
				this.steps[1].formData.endTime = undefined;

				this.steps[1].valid = false; // force invalid
				this.steps[2].valid = true;
				this.steps[3].valid = true;
				this.steps[4].valid = true;
				if (this.steps[5]) {
					this.steps[5].valid = true;
				}
			}

			this.isLoading = false;
		},
	},
};
</script>

<style lang="scss" scoped>
	.navigation-panel {
		position: fixed;
		left: rem(56);
		right: 0;
		bottom: 0;
		padding: rem(12) 0;
		box-shadow: 0 1px 2px 0 $color-gray-07, 0 4px 16px 0 $color-gray-15;
		background-color: $color-white;
		z-index: 9;

		@media (max-width: 991.98px) {
			left: 0;
		}

		::v-deep button {
			min-width: rem(80);
		}
	}
</style>
