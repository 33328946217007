<template>
	<CRow>
		<CCol md="6">
			<h6>Group {{ Number(index) + 1 }}</h6>
		</CCol>
		<CCol md="6" class="text-right">
			<CButton
				class="transparent btn-remove"
				@click="$emit('onRemovePaymentGroup', index)"
			>
				<CIcon
					name="cil-trash"
					size="custom"
					custom-classes="c-icon c-icon-custom-size icon-trash"
				/>
				<span>Remove this group</span>
			</CButton>
		</CCol>
		<CCol md="6">
			<BaseDropDown
				:value="payment.paymentMethod.$model"
				:options="paymentMethodOptions"
				:searchable="false"
				:allow-empty="false"
				:is-valid="!payment.paymentMethod.$error"
				:invalid-feedback="$t('global.error.required')"
				label-drop-down="Payment method*"
				label="label"
				track-by="id"
				@input="(value) => handlePaymentMethodChange({ key: 'paymentMethod', index, value })"
			/>
		</CCol>
		<CCol md="6">
			<BaseDropDown
				:value="payment.paymentChannel.$model"
				label-drop-down="Payment channel"
				:options="paymentChannelOptions"
				:is-valid="!payment.paymentChannel.$error"
				:invalid-feedback="$t('global.error.required')"
				:searchable="false"
				:disabled="disableChannel"
				label="label"
				track-by="id"
				@input="(value) => handlePaymentMethodChange({ key: 'paymentChannel', index, value })"
			/>
		</CCol>
		<CCol md="12">
			<CInput
				v-model.trim="payment.campaignCode.$model"
				class="mb-0"
				:is-valid="!payment.campaignCode.$error && null"
				label="BIN Campaign no."
				placeholder="BIN Campaign no."
				@change="payment.campaignCode.$touch()"
			/>
			<small class="form-text text-muted w-100 mt-2">Only use for Credit card/Debit card payment method</small>
		</CCol>
	</CRow>
</template>

<script>
export default {
	name: 'PromotionDiscountOnTopPaymentGroup',

	props: {
		payment: {
			type: Object,
			default: null,
		},
		index: {
			type: Number,
			default: 0,
		},
		paymentMethodOptions: {
			type: Array,
			default: () => [],
		},
	},

	data() {
		return {
			paymentChannelOptions: [],
			isValid: true,
		};
	},

	computed: {
		disableChannel() {
			if (this.paymentChannelOptions?.length > 0) {
				return false;
			}
			return true;
		},
	},

	watch: {
		paymentMethodOptions: {
			deep: true,
			handler(newVal) {
				const paymentMethodModel = this.payment.paymentMethod.$model;
				if (paymentMethodModel) {
					const currentMethod = newVal.find((method) => method.id === paymentMethodModel.id);
					this.paymentChannelOptions = currentMethod?.channels ?? [];
				}
			},
		},
	},

	created() {
		// Init channel options
		const paymentMethodModel = this.payment.paymentMethod.$model;
		if (paymentMethodModel) {
			const currentMethod = this.paymentMethodOptions.find((method) => method.id === paymentMethodModel.id);
			this.paymentChannelOptions = currentMethod?.channels ?? [];
		}
	},

	methods: {
		handlePaymentMethodChange({ key, index, value }) {
			// if select a selected method it will return null.
			if (!value) {
				return;
			}

			if (key === 'paymentMethod') {
				this.paymentChannelOptions = value.channels;
				this.resetMethodChannel();
			}

			this.payment.$touch();
			this.$emit('onChange', { key, index, value });
		},

		resetMethodChannel() {
			this.payment.paymentChannel.$model = null;
		},
	},
};
</script>

<style lang="scss" scoped>
.btn-remove {
	@include typo-caption-2;

	margin: 0;
	padding: 0;

	line-height: rem(24);
}

.icon-trash {
	width: rem(12);
	margin: 0 rem(4) 0 0;
}
</style>
