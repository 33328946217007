<template>
	<div>
		<CRow>
			<CCol md="12">
				<h2 class="typo-h4 position-relative">
					Shipping condition
				</h2>
			</CCol>
		</CRow>
		<CRow>
			<CCol md="12">
				<div class="d-block mt-4 mb-3">
					<label class="mb-0">
						<h6 data-test-id="title">
							Shipping method
						</h6>
					</label>
					<CButton
						type="button"
						class="btn btn-add float-right"
						color="secondary"
						data-test-id="button-add"
						@click="$refs['modal-shipping-method'].open()"
					>
						{{ `${shippingMethods.length ? 'Edit' : 'Add'} shipping method` }}
					</CButton>
				</div>
			</CCol>
		</CRow>
		<CRow>
			<CCol v-if="!shippingMethods.length" class="mb-3" lg="12">
				<BaseNoItemBanner
					text="There is no shipping method added."
				/>
			</CCol>
			<CCol v-else lg="12">
				<ul class="shipping-method-list">
					<li v-for="item in shippingMethods" :key="item.id" class="list-item typo-body-2">
						{{ item.name }}
					</li>
				</ul>
			</CCol>
		</CRow>

		<ModalShippingMethod
			ref="modal-shipping-method"
			:list="shippingMethods"
			@onConfirm="handleShippingMethodListChange"
		/>
	</div>
</template>


<script>
import ModalShippingMethod from '@/components/ModalShippingMethod.vue';

export default {
	name: 'PromotionShippingCondition',
	components: {
		ModalShippingMethod,
	},
	props: {
		shippingMethods: {
			type: Array,
			default: () => [],
		},
	},
	methods: {
		handleShippingMethodListChange(list) {
			let transformList = [];
			if (list && Array.isArray(list)) {
				transformList = list.map(({ id, name }) => ({ id, name }));
			}
			this.$emit('update:shippingMethods', transformList);
		},
	},
};
</script>

<style lang="scss" scoped>
	.shipping-method-list {
		overflow-y: auto;
		min-height: rem(60);
		max-height: rem(468);
		padding: rem(20) rem(16) rem(8);
		border-radius: rem(4);
		border: solid 1px $color-gray-400;
		list-style: none;

		.list-item {
			border-radius: rem(4);
			background-color: $color-gray-300;
			color: $color-black-90;
			display: inline-block;
			padding: 0 rem(6);
			margin-right: rem(12);
			margin-bottom: rem(12);
		}
	}
</style>