<template>
	<CRow class="mb-3">
		<CCol :md="skuSize" class="d-flex">
			<img
				class="thumbnail-54 ml-2"
				data-test-id="thumbnail"
				:src="thumbnail"
			>
			<div class="pl-3">
				<div class="typo-body-2" data-test-id="name">
					{{ name }}
				</div>
				<div
					class="typo-caption color-black-45 mb-2"
					data-test-id="sku"
				>
					{{ sku }}
				</div>
				<CBadge v-if="isPrimary" class="badge-status badge-inactive">
					Primary Product
				</CBadge>
			</div>
		</CCol>

		<CCol v-if="showQuantity" md="3">
			<BaseInputNumber
				v-model="localQty"
				class="input-quantity"
				:is-valid="!$v.localQty.$error"
				:invalid-feedback="$t('global.error.minimumNumber', { number: 1 })"
				append-text="Qty."
				text-align="left"
				placeholder="0"
			/>
		</CCol>

		<CCol v-if="showRemove" md="2">
			<CButton
				class="transparent"
				data-test-id="button-remove"
				@click="handleRemove"
			>
				<CIcon name="cil-trash" class="color-black-45" />
			</CButton>
		</CCol>
	</CRow>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, minValue } from 'vuelidate/lib/validators';
import { IMAGE_PLACEHOLDER_URL } from '../enums/general';

export default {
	name: 'SKUListItem',
	mixins: [validationMixin],
	props: {
		thumbnail: {
			type: String,
			default: IMAGE_PLACEHOLDER_URL,
		},
		name: {
			type: String,
			default: null,
		},
		sku: {
			type: String,
			default: null,
		},
		quantity: {
			type: String,
			default: null,
		},
		isPrimary: {
			type: Boolean,
			default: false,
		},
		showQuantity: {
			type: Boolean,
			default: false,
		},
	},
	validations: {
		localQty: {
			required,
			minValue: minValue(1),
		},
	},
	computed: {
		localQty: {
			get() {
				return this.quantity;
			},
			set(value) {
				this.$v.$touch();

				this.$emit('onChangeQty', value);
			},
		},
		showRemove() {
			return this.$listeners.onRemove;
		},
		skuSize() {
			if (this.showQuantity && this.showRemove) {
				return '7';
			} else if (this.showQuantity) {
				return '9';
			} else if (this.showRemove) {
				return '10';
			}

			return '12';
		},
	},
	methods: {
		handleRemove() {
			this.$emit('onRemove');
		},
	},
};
</script>

<style lang="scss" scoped>
	::v-deep .input-quantity {
		.input-group-text {
			// override padding right append text
			padding-right: rem(8) !important;
		}
	}
</style>
