<template>
	<div class="mt-4">
		<div>
			<label>Payment methods*</label>
		</div>
		<div class="py-3 px-3 rounded-sm bg-gray-100">
			<BaseLoading v-if="isLoading" />
			<template v-else>
				<div v-if="payments.length === 0" class="text-center">
					No payment group
				</div>
				<template v-else>
					<div
						v-for="(vPayment, index) in $v.payments.$each.$iter"
						:key="index"
						class="payment-group"
					>
						<PromotionDiscountOnTopPaymentGroup
							:payment="vPayment"
							:index="Number(index)"
							:payment-method-options="paymentMethodOptions"
							@onRemovePaymentGroup="onRemovePaymentGroup"
							@onChange="onChange"
						/>
					</div>
				</template>
			</template>
		</div>
		<template v-if="!isLoading">
			<div v-if="errorNoPaymentGroup" class="invalid-feedback d-block">
				{{ errorNoPaymentGroup }}
			</div>
			<a
				href="#"
				class="mt-4 d-inline-block"
				@click.prevent="onAddPaymentGroup"
			>
				Add new payment group
			</a>
		</template>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required, minLength, requiredIf } from 'vuelidate/lib/validators';
import PromotionDiscountOnTopPaymentGroup from '@/components/PromotionDiscountOnTopPaymentGroup.vue';
import { cloneDeep } from '../assets/js/helpers';

const defaultOnTopPaymentGroup = {
	paymentMethod: null,
	paymentChannel: null, // Optional if no channel
	campaignCode: '', // Optional
};

export default {
	name: 'PromotionDiscountOnTopPayment',

	components: {
		PromotionDiscountOnTopPaymentGroup,
	},

	validations: {
		payments: {
			required,
			minLength: minLength(1),
			$each: {
				paymentMethod: {
					required,
				},
				paymentChannel: {
					required: requiredIf((vm) => {
						return vm?.paymentMethod?.channels?.length > 0;
					}),
				},
				campaignCode: {},
			},
		},
	},

	props: {
		isEdit: {
			type: Boolean,
			default: false,
		},
		onTopPayments: {
			type: Array,
			default: () => [],
		},
	},

	data() {
		return {
			isLoading: true,
			payments: [],
			paymentMethodOptions: [],
		};
	},

	computed: {
		...mapGetters({
			paymentMethodDetailsList: 'payments/paymentMethodDetailsList',
		}),

		errorNoPaymentGroup() {
			if (!this.$v.payments.required || !this.$v.payments.minLength) {
				return this.$t('global.error.required');
			}
			return null;
		},
	},

	async created() {
		// If no payment method list data, fetch it.
		if (!(this.paymentMethodDetailsList?.length > 0)) {
			await this.getPaymentMethods();
		}

		const clonePaymentMethodDetailsList = cloneDeep(this.paymentMethodDetailsList);

		clonePaymentMethodDetailsList.sort(({ group: groupA }, { group: groupB }) => {
			if (groupA.nameEN > groupB.nameEN) {
				return 1;
			}
			if (groupB.nameEN > groupA.nameEN) {
				return -1;
			}
			return 0;
		});

		this.paymentMethodOptions = clonePaymentMethodDetailsList.map((methodDetail) => {
			return {
				id: methodDetail.id,
				label: `${methodDetail.group.nameEN} - ${methodDetail.nameEN}`,
				channels: methodDetail.paymentChannels.map((channel) => {
					return {
						id: channel.id,
						label: channel.nameEN,
						$isDisabled: false,
					};
				}),
				$isDisabled: false,
			};
		});

		// Map data from user with option data
		this.payments = this.onTopPayments.map((payment) => {
			const newPayment = { ...defaultOnTopPaymentGroup };
			const paymentMethodIndex = this.getPaymentMethodOptionIndexById(payment?.paymentMethod?.id);

			newPayment.paymentMethod = paymentMethodIndex >= 0 ? this.paymentMethodOptions[paymentMethodIndex] : null;
			newPayment.campaignCode = payment?.campaignCode ?? '';

			if (newPayment.paymentMethod) {
				const paymentChannelIndex = this.getPaymentChannelOptionIndexById(newPayment.paymentMethod.channels, payment?.paymentChannel?.id);
				newPayment.paymentChannel = paymentChannelIndex >= 0 ? newPayment.paymentMethod.channels[paymentChannelIndex] : null;
			}

			return newPayment;
		});

		this.disableSelectedOptions();

		this.isLoading = false;
	},

	methods: {
		...mapActions({
			getPaymentMethodGroups: 'payments/getPaymentMethodGroups',
			getPaymentMethods: 'payments/getPaymentMethods',
		}),

		emitOnTopPayments() {
			this.$emit('update:onTopPayments', this.payments);
		},

		onAddPaymentGroup() {
			this.payments = [...this.payments, { ...defaultOnTopPaymentGroup }];
			this.emitOnTopPayments();
		},

		onRemovePaymentGroup(removeIndex) {
			const newPayments = this.payments.filter((_payment, index) => {
				return index !== removeIndex;
			});

			this.payments = [...newPayments];

			this.disableSelectedOptions();
			this.emitOnTopPayments();
		},

		onChange({ key, index, value }) {
			this.$set(
				this.payments,
				index,
				{
					...this.payments[index],
					[key]: value,
				},
			);

			this.disableSelectedOptions();
			this.emitOnTopPayments();
		},

		disableSelectedOptions() {
			this.resetOptions();

			for (const payment of this.payments) {
				// If select payment method and payment channel
				if (payment.paymentMethod && payment.paymentChannel) {
					const methodIndex = this.getPaymentMethodOptionIndexById(payment.paymentMethod.id);
					const method = this.paymentMethodOptions[methodIndex];
					const channelIndex = this.getPaymentChannelOptionIndexById(method.channels, payment.paymentChannel.id);

					method.channels[channelIndex].$isDisabled = true;

					// If all of channel is disabled, disable
					const allChannelIsSelected = method.channels.every((channel) => channel.$isDisabled === true);

					if (allChannelIsSelected) {
						method.$isDisabled = true;
					}
				} else if (payment.paymentMethod) {
					const methodIndex = this.getPaymentMethodOptionIndexById(payment.paymentMethod.id);
					const method = this.paymentMethodOptions[methodIndex];

					// If no channel and it selected disable it
					if (method.channels.length === 0) {
						method.$isDisabled = true;
					}
				}
			}
		},

		resetOptions() {
			this.paymentMethodOptions = this.paymentMethodOptions.map((payment) => {
				return {
					...payment,
					channels: payment.channels.map((channel) => {
						return {
							...channel,
							$isDisabled: false,
						};
					}),
					$isDisabled: false,
				};
			});
		},

		getPaymentMethodOptionIndexById(id) {
			return this.paymentMethodOptions.findIndex((method) => method.id === id);
		},

		getPaymentChannelOptionIndexById(channels = [], id) {
			return channels.findIndex((value) => value.id === id);
		},
	},
};
</script>

<style lang="scss" scoped>
.payment-group {
	padding: rem(16);

	border-radius: 4px;
	border: 1px solid $color-gray-400;
	background: $color-white;

	&:not(:last-child) {
		margin-bottom: rem(16);
	}
}
</style>
