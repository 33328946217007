<template>
	<div class="remark-container">
		<CRow>
			<CCol md="12">
				<div class="d-block mt-4 mb-3">
					<label class="mb-0">
						<h2 class="typo-h4 mb-3" data-test-id="remark-title">
							Remark
						</h2>
					</label>
					<ul v-if="remarkItems.length" class="remark-items list-unstyled ml-0 mb-3 pb-2">
						<li v-for="(remarkItem, key) in remarkItems" :key="key" class="remark-item">
							<div class="remark-item-user typo-body-2 font-weight-bold mb-1">
								{{ remarkItem.user.name }}
							</div>
							<div class="remark-item-updated typo-caption-2 mb-2">
								Created: {{ remarkItem.updatedAt }}
							</div>
							<div class="remark-item-content typo-body-2">{{ remarkItem.message }}</div>
						</li>
					</ul>
					<form @submit.prevent="handleSubmitRemark">
						<CTextarea
							v-model="remark"
							rows="6"
							placeholder="Type order remark here…"
						/>
						<div class="d-flex">
							<CButton
								:disabled="isCreating || !remark"
								type="submit"
								class="btn btn-add-remark ml-auto"
								color="secondary"
								data-test-id="button-add-remark"
							>
								{{ isCreating ? 'Adding comment' : 'Add new comment' }}
							</CButton>
						</div>
					</form>
				</div>
			</CCol>
			<CCol md="12" class="mt-4">
				<hr>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import { transformedPromotionRemarkDetails } from '../assets/js/transform/promotions';

export default {
	name: 'PromotionRemarks',

	props: {
		promotionId: {
			type: Number,
			default: null,
		},
		remarks: {
			type: Array,
			default: () => [],
		},
		value: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			remark: null,
			isCreating: false,
		};
	},
	computed: {
		remarkItems: {
			get() {
				return this.remarks;
			},
			set(value) {
				this.$emit('update:remarks', value);
			},
		},
	},
	methods: {
		...mapActions({
			createPromotionRemark: 'promotions/createPromotionRemark',
		}),

		async handleSubmitRemark() {
			this.isCreating = true;

			try {
				const { data } = await this.createPromotionRemark({
					id: this.promotionId,
					params: { message: this.remark },
				});

				// Add new remark to first of array
				this.remarkItems = [
					transformedPromotionRemarkDetails(data),
					...this.remarkItems,
				];
			} finally {
				this.isCreating = false;
				this.remark = null;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.remark-items {
		border-bottom: 1px solid $color-gray-300;
	}

	.remark-item {
		border: 1px solid $color-gray-300;
		border-radius: rem(4);
		padding: rem(16);
		margin-bottom: rem(12);

		// .remark-item-content
		&-content {
			white-space: pre-wrap;
		}
	}
</style>
