import { render, staticRenderFns } from "./CardDescriptionInfo.vue?vue&type=template&id=3e143559&scoped=true&"
import script from "./CardDescriptionInfo.vue?vue&type=script&lang=js&"
export * from "./CardDescriptionInfo.vue?vue&type=script&lang=js&"
import style0 from "./CardDescriptionInfo.vue?vue&type=style&index=0&id=3e143559&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e143559",
  null
  
)

export default component.exports