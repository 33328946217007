<template>
	<div>
		<CRow class="typo-body-2 px-3">
			<CCol md="12" class="mt-2">
				<BaseDropDown
					:value="selectedStockOption"
					:options="freebieStockOption"
					label="label"
					track-by="key"
					label-drop-down="Insufficient stock option"
					@select="handleStockOptionKeyChange"
				/>
			</CCol>
		</CRow>

		<template v-if="isIgnoreInsufficientStock">
			<CRow class="typo-body-2 px-3">
				<CCol md="12">
					<CInput
						v-model.trim="$v.stockOptionParams.noticeTextEn.$model"
						:is-valid="!$v.stockOptionParams.noticeTextEn.$error && null"
						:invalid-feedback="$t('global.error.required')"
						type="text"
						placeholder="Type notice text"
						label="Notice text (EN)*"
					/>
				</CCol>
			</CRow>
			<CRow class="typo-body-2 px-3">
				<CCol md="12">
					<CInput
						v-model.trim="$v.stockOptionParams.noticeTextTh.$model"
						:is-valid="!$v.stockOptionParams.noticeTextTh.$error && null"
						:invalid-feedback="$t('global.error.required')"
						type="text"
						placeholder="Type notice text"
						label="Notice text (TH)*"
					/>
				</CCol>
			</CRow>
		</template>
		<template v-else-if="isSubstituteWithOtherSku">
			<CRow class="typo-body-2 px-3">
				<CCol>
					<div class="d-flex mb-3 justify-content-between">
						<div class="mb-0">
							<h6 data-test-id="title">Secondary freebie SKU*</h6>
						</div>
						<div class="btn-add-wrapper">
							<CButton
								type="button"
								class="btn btn-add"
								color="secondary"
								data-test-id="button-add"
								@click="$refs['modal-sku'].open()"
							>
								{{ `${secondarySkuList.length ? 'Edit' : 'Add'} freebie SKU` }}
							</CButton>
						</div>
					</div>
					<CRow>
						<CCol v-if="secondarySkuList.length" lg="12">
							<CRow class="mb-2">
								<CCol md="8">
									<span class="label">SKUs</span>
								</CCol>
								<CCol md="2">
									<span class="label text-right">Stock qty.</span>
								</CCol>
								<CCol md="2">
									<span class="label">Reward qty.</span>
								</CCol>
							</CRow>
							<ul class="list-unstyled p-0">
								<li
									v-for="product in secondarySkuList"
									:key="product.sku"
									class="border rounded-sm border-secondary pt-3 mb-3 list-unstyled"
								>
									<CRow
										class="typo-body-2 d-flex px-3 text-decoration-none"
									>
										<CCol md="8">
											<SKUListItem
												:thumbnail="product.thumbnail"
												:name="product.name"
												:sku="product.sku"
												:is-primary="product.isPrimary"
											/>
										</CCol>
										<CCol md="2" class="item-quantity text-right">
											{{ product.stockAvailable | numberFormat }}
										</CCol>
										<CCol md="2" class="item-quantity text-right">
											{{ product.rewardQty | numberFormat }}
										</CCol>
									</CRow>
								</li>
							</ul>
						</CCol>
					</CRow>
				</CCol>
			</CRow>
		</template>
		<template v-else-if="isSubstituteWithDiscount">
			<CRow class="typo-body-2 px-3">
				<CCol md="6">
					<BaseInputNumber
						v-model.trim="$v.stockOptionParams.amountDiscount.$model"
						:decimal-scale="2"
						:is-valid="!$v.stockOptionParams.amountDiscount.$error || null"
						:invalid-feedback="discountErrors"
						class="mb-4"
						append-text="฿"
						placeholder="Type Fixed price"
						label="Discount value*"
						text-align="left"
					/>
				</CCol>
			</CRow>
		</template>


		<ModalSkuFreebie
			ref="modal-sku"
			only-one-item
			:products="secondarySkuList"
			@update:products="handleProductListChange"
		/>
	</div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import ModalSkuFreebie from '@/components/ModalSkuFreebie.vue';
import SKUListItem from '@/components/SKUListItem.vue';

import { validationMixin } from 'vuelidate';
import { required, minValue } from 'vuelidate/lib/validators';
import { FREEBIE_STOCK_OPTION_KEYS, FREEBIE_STOCK_OPTIONS } from '../enums/promotions';
import { cloneDeep } from '../assets/js/helpers';

export default {
	name: 'PromotionDiscountFreebieStockOptions',

	components: {
		ModalSkuFreebie,
		SKUListItem,
	},

	mixins: [validationMixin],

	props: {
		defaultData: {
			type: Object,
			default: null,
		},
	},

	validations() {
		let paramsRule = {};

		if (this.isIgnoreInsufficientStock) {
			paramsRule = {
				noticeTextEn: { required },
				noticeTextTh: { required },
			};
		} else if (this.isSubstituteWithOtherSku) {
			paramsRule = {
				secondarySku: { required },
			};
		} else if (this.isSubstituteWithDiscount) {
			paramsRule = {
				amountDiscount: {
					required,
					minValue: minValue(0),
				},
			};
		}

		return {
			stockOptionParams: paramsRule,
		};
	},

	data() {
		const defaultKey = FREEBIE_STOCK_OPTION_KEYS.DEFAULT;
		const defaultParams = {
			noticeTextEn: '',
			noticeTextTh: '',
			secondarySku: {},
			amountDiscount: 0,
		};

		return {
			FREEBIE_STOCK_OPTIONS,
			defaultKey,
			defaultParams,

			stockOptionKey: defaultKey,
			stockOptionParams: defaultParams,
		};
	},

	computed: {
		selectedStockOption() {
			return FREEBIE_STOCK_OPTIONS.find((option) => {
				return option.key === this.stockOptionKey;
			});
		},

		isIgnoreInsufficientStock() {
			return this.selectedStockOption.key === FREEBIE_STOCK_OPTION_KEYS.IGNORE_INSUFFICIENT_STOCK;
		},

		isSubstituteWithOtherSku() {
			return this.selectedStockOption.key === FREEBIE_STOCK_OPTION_KEYS.SUBSTITUTE_WITH_OTHER_SKU;
		},
		secondarySkuList() {
			return isEmpty(this.stockOptionParams.secondarySku) ? [] : [this.stockOptionParams.secondarySku];
		},

		isSubstituteWithDiscount() {
			return this.selectedStockOption.key === FREEBIE_STOCK_OPTION_KEYS.SUBSTITUTE_WITH_DISCOUNT;
		},
		discountErrors() {
			if (this.isSubstituteWithDiscount && !this.$v.stockOptionParams.amountDiscount.required) {
				return this.$t('global.error.required');
			} else if (this.isSubstituteWithDiscount && !this.$v.stockOptionParams.amountDiscount.minValue) {
				return 'Value must be greater than 0';
			}

			return null;
		},

		// TODO: remove this computed (freebieStockOption) after API success
		// Then use FREEBIE_STOCK_OPTIONS instead this computed
		freebieStockOption() {
			return FREEBIE_STOCK_OPTIONS.filter((option) => option.key !== FREEBIE_STOCK_OPTION_KEYS.SUBSTITUTE_WITH_DISCOUNT);
		},
	},

	watch: {
		defaultData: {
			deep: true,
			handler(data, prevData) {
				if (data && data !== prevData) {
					this.stockOptionKey = data.key;
					this.stockOptionParams = data.params;
				} else if (!data) {
					this.resetData();
				}
			},
		},

		stockOptionKey: {
			deep: true,
			handler(key) {
				const data = { key, params: this.stockOptionParams };
				this.$emit('update:is-valid', {
					valid: !this.$v.$invalid,
					data,
				});
			},
		},

		stockOptionParams: {
			deep: true,
			handler(params) {
				const data = { key: this.stockOptionKey, params };
				this.$emit('update:is-valid', {
					valid: !this.$v.$invalid,
					data,
				});
			},
		},
	},

	created() {
		if (this.defaultData) {
			this.stockOptionKey = this.defaultData.key;
			this.stockOptionParams = this.defaultData.params;
		}
	},

	methods: {
		resetData() {
			this.stockOptionKey = cloneDeep(this.defaultKey);
			this.stockOptionParams = cloneDeep(this.defaultParams);
		},

		handleStockOptionKeyChange(updatedStockOption) {
			this.resetData();
			this.stockOptionKey = cloneDeep(updatedStockOption.key);

			if (updatedStockOption.key === FREEBIE_STOCK_OPTION_KEYS.DEFAULT) {
				this.$v.stockOptionParams.$reset();
			}

			this.$v.$reset();
		},

		handleProductListChange(list) {
			this.stockOptionParams.secondarySku = list[0];
		},

	},
};
</script>
