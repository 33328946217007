<template>
	<div>
		<CRow>
			<CCol md="12">
				<h2 class="typo-h4 position-relative">
					Cart condition
				</h2>
			</CCol>
		</CRow>
		<CRow class="mt-4">
			<CCol md="8">
				<label>Condition type</label>
			</CCol>
			<CCol md="4" class="text-right">
				<CInputRadioGroup
					:options="CONDITION_TYPE_OPTIONS"
					:custom="true"
					:inline="true"
					:checked="conditionType"
					size="sm"
					class="radio-group"
					name="cart-condition-type"
					@update:checked="handleChangeConditionType"
				/>
			</CCol>
		</CRow>

		<CRow class="mt-2">
			<CCol md="4">
				<label class="pt-3 mt-3">Item quantity</label>
			</CCol>
			<CCol md="4">
				<BaseDropDown
					:value="getSelectedRule(itemQuantity.rule, CART_CONDITION_TYPES.QUANTITY)"
					:options="getRuleOptions(CART_CONDITION_TYPES.QUANTITY)"
					:searchable="false"
					class="select-custom"
					label="label"
					track-by="value"
					label-drop-down="Rule"
					@input="handleRuleChange(CART_CONDITION_TYPES.QUANTITY, $event)"
				/>
			</CCol>
			<CCol md="4">
				<div class="condition-value">
					<label>Value</label>
					<div v-if="!itemQuantity.rule" class="empty-value">
						<span>Select value</span>
					</div>
					<div v-else :class="{ 'is-invalid': itemQuantity.rule && $v.itemQuantity.value.$error }">
						<BaseInputNumber
							v-model="itemQuantity.value"
							prepend-text="Qty."
							text-align="right"
							placeholder="0"
							@input="handleValueChange"
						/>
					</div>
					<div
						v-if="itemQuantity.rule && $v.itemQuantity.value.$error"
						class="invalid-feedback d-block"
					>
						{{ $t('global.error.required') }}
					</div>
				</div>
			</CCol>
		</CRow>

		<CRow class="mt-2">
			<CCol md="4">
				<label class="pt-3 mt-3">Purchase amount</label>
			</CCol>
			<CCol md="4">
				<BaseDropDown
					:value="getSelectedRule(purchaseAmount.rule, CART_CONDITION_TYPES.PURCHASE_AMOUNT)"
					:options="getRuleOptions(CART_CONDITION_TYPES.PURCHASE_AMOUNT)"
					:searchable="false"
					class="select-custom"
					label="label"
					track-by="value"
					label-drop-down="Rule"
					@input="handleRuleChange(CART_CONDITION_TYPES.PURCHASE_AMOUNT, $event)"
				/>
			</CCol>
			<CCol md="4">
				<div class="condition-value">
					<label>Value</label>
					<div v-if="!purchaseAmount.rule" class="empty-value">
						<span>Select value</span>
					</div>
					<div v-else :class="{ 'is-invalid': purchaseAmount.rule && $v.purchaseAmount.value.$error }">
						<BaseInputNumber
							v-model="purchaseAmount.value"
							:decimal-scale="2"
							prepend-text="฿"
							text-align="right"
							placeholder="0"
							@input="handleValueChange"
						/>
					</div>
					<div
						v-if="purchaseAmount.rule && $v.purchaseAmount.value.$error"
						class="invalid-feedback d-block"
					>
						{{ $t('global.error.required') }}
					</div>
				</div>
			</CCol>
		</CRow>

		<CRow class="mt-2 pt-2">
			<CCol md="8">
				<label class="pt-2">Calculate purchase amount from</label>
			</CCol>
			<CCol md="4">
				<BaseDropDown
					v-model="purchaseSubtractDiscount"
					:options="PURCHASE_SUBTRACT_DISCOUNT_OPTIONS"
					:searchable="false"
					:allow-empty="false"
					class="select-custom"
					label="label"
					track-by="value"
					label-drop-down=""
					@input="handlePurchaseSubtractDiscountChange"
				/>
			</CCol>
		</CRow>
	</div>
</template>


<script>
import { validationMixin } from 'vuelidate';
import { requiredIf } from 'vuelidate/lib/validators';

import { numberFormat } from '../assets/js/helpers';
import {
	CART_CONDITION_TYPES,
	CONDITION_TYPE_OPTIONS,
	CONDITION_TYPES,
	CONDITION_KEY_LABELS,
	CONDITION_KEY_OPTIONS,
	CONDITION_RULE_OPTIONS,
	PURCHASE_SUBTRACT_DISCOUNT_OPTIONS,
} from '../enums/promotions';

import { transformedCartConditions, transformedFlatCartCondition } from '../assets/js/transform/promotions';

export default {
	name: 'PromotionCartCondition',
	validations() {
		return {
			purchaseAmount: {
				value: {
					required: requiredIf((vm) => vm.rule),
				},
			},
			itemQuantity: {
				value: {
					required: requiredIf((vm) => vm.rule),
				},
			},
		};
	},
	mixins: [validationMixin],
	props: {
		defaultData: {
			type: Object,
			default: () => ({}),
		},
		dataPurchaseSubtractDiscount: {
			type: Object,
			default: null,
		},
	},
	data() {
		const { type = CONDITION_TYPES.ALL, param = {} } = this.defaultData || {};
		const { purchaseAmount, itemQuantity } = transformedFlatCartCondition((param?.conditions));
		const purchaseSubtractDiscount = this.dataPurchaseSubtractDiscount;
		return {
			CART_CONDITION_TYPES,
			CONDITION_KEY_LABELS,
			CONDITION_TYPES,
			CONDITION_TYPE_OPTIONS,
			CONDITION_KEY_OPTIONS,
			PURCHASE_SUBTRACT_DISCOUNT_OPTIONS,
			conditionType: type,
			purchaseAmount: purchaseAmount || {
				rule: null,
				value: null,
			},
			itemQuantity: itemQuantity || {
				rule: null,
				value: null,
			},
			purchaseSubtractDiscount: purchaseSubtractDiscount || PURCHASE_SUBTRACT_DISCOUNT_OPTIONS[0], // Default is 'Price before discount',
		};
	},
	methods: {
		numberFormat,
		getSelectedKey(selectedKey = null) {
			const findStatus = ({ value }) => value === selectedKey;
			return Object.values(CONDITION_KEY_OPTIONS).find(findStatus);
		},
		getSelectedRule(selectedRule = null, selectedKey = null) {
			const findStatus = ({ value }) => value === selectedRule;
			const ruleOptions = this.getRuleOptions(selectedKey);
			return Object.values(ruleOptions).find(findStatus);
		},
		getRuleOptions(selectedKey = null) {
			if (selectedKey) {
				return [
					{ value: null, label: 'Select rule' },
					...CONDITION_RULE_OPTIONS[selectedKey],
				];
			}

			return [];
		},
		handleRuleChange(key, event) {
			const rule = (event || {}).value;
			if (key === CART_CONDITION_TYPES.PURCHASE_AMOUNT) {
				this.purchaseAmount.rule = rule;

				if (!rule) {
					this.purchaseAmount.value = null;
				}
			}

			if (key === CART_CONDITION_TYPES.QUANTITY) {
				this.itemQuantity.rule = rule;

				if (!rule) {
					this.itemQuantity.value = null;
				}
			}

			this.$v.$reset();
			this.handleUpdateValue();
		},
		handleChangeConditionType(value) {
			this.conditionType = value;
			this.handleUpdateValue();
		},
		handleValueChange() {
			this.handleUpdateValue();
		},
		handleUpdateValue() {
			this.$v.$touch();

			const isValid = !this.$v.$invalid;
			const { purchaseAmount, itemQuantity } = this;
			let data = null;

			if (isValid) {
				data = {};
				if (this.purchaseAmount.value) {
					data.purchaseAmount = purchaseAmount;
				}

				if (this.itemQuantity.value) {
					data.itemQuantity = itemQuantity;
				}
			}

			this.$emit(
				'onUpdate:cartCondition',
				{
					valid: isValid,
					data: transformedCartConditions({ type: this.conditionType, condition: data }),
				},
			);
		},
		handlePurchaseSubtractDiscountChange() {
			this.$emit(
				'onUpdate:purchaseSubtractDiscountCondition',
				this.purchaseSubtractDiscount,
			);
		},
	},
};
</script>

<style lang="scss" scoped>
	.condition-value {
		span {
			line-height: rem(34);
		}

		.empty-value {
			height: rem(34);
			padding: 0 rem(12);
			color: $color-black-25;
			background-color: $color-gray-200;
			border-radius: rem(4);
		}

		.is-invalid {
			border: 1px solid $color-alert;
			border-radius: rem(4);
		}
	}
</style>
