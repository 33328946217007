<template>
	<div>
		<label>
			Manual create e-Coupon
		</label>
		<p class="typo-body-2 color-black-45 mb-4">
			Create code by importing file. Support .xls, xlsx with the maximum file of 10 MB
		</p>
		<CRow>
			<CCol>
				<CButton
					class="transparent"
					color="secondary"
					data-test-id="import-coupon-code"
					@click="importCouponCode"
				>
					Import .xlsx
				</CButton>
			</CCol>
		</CRow>
		<CRow
			v-if="showCouponList"
			class="mt-4"
		>
			<CCol md="12">
				<p class="typo-body-2 mt-2 mb-2">
					Total {{ totalCouponCodes }} code(s)
				</p>
			</CCol>
			<CCol md="12">
				<div class="coupon-codes p-3">
					<p class="typo-caption-2 mb-0">{{ couponCodesText }}</p>
				</div>
			</CCol>
			<CCol v-if="!isSingleCode" md="12" class="mt-3">
				<div>
					<CouponBadge :label="couponCodePattern" />
					<span class="typo-caption-2">{{ `If it’s multi-code, the code will be displayed as "${couponCodePattern}" on storefront.` }}</span>
				</div>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import { mapActions } from 'vuex';

import CouponBadge from '@/components/CouponBadge.vue';

import { importCouponCodesAPI } from '../services/api/promotions.api';

import { getECouponCodePattern } from '../assets/js/helpers';

export default {
	name: 'PromotionECouponManual',

	components: {
		CouponBadge,
	},

	props: {
		dataECoupon: {
			type: Object,
			default: null,
		},
		isEditMode: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		const { couponCodes = [] } = this.dataECoupon || {};

		return {
			formData: {
				couponCodes,
				newCouponCodes: [],
			},
		};
	},

	computed: {
		mergeCouponCodes() {
			if (Array.isArray(this.formData.couponCodes) && Array.isArray(this.formData.newCouponCodes)) {
				return [...new Set([...this.formData.couponCodes, ...this.formData.newCouponCodes])];
			}

			return [];
		},
		couponCodesText() {
			if (!this.formData.couponCodes || !this.formData.couponCodes.length) {
				return '';
			}

			// Fully summary
			if (this.mergeCouponCodes.length > 0) {
				let valueText = '';
				const maximumValueList = 5;

				if (this.formData.couponCodes.length > maximumValueList) {
					// Get at most 5 items
					valueText = `${this.mergeCouponCodes.slice(0, maximumValueList).join(', ')} and ${(this.mergeCouponCodes.length - maximumValueList)} more codes`;
				} else {
					valueText = this.mergeCouponCodes.join(', ');
				}

				return valueText;
			}

			return '';
		},
		codePrefix() {
			if (!this.formData.couponCodes || !Array.isArray(this.formData.couponCodes)) {
				return null;
			}

			// First 3 charecters will be Prefix value
			const defaultPrefixLength = 3;
			const firstCouponCode = this.formData.couponCodes[0];

			// If code length longer than default, substring
			// If not, use the code to be prefix
			return firstCouponCode.length > defaultPrefixLength
				? firstCouponCode.substring(0, defaultPrefixLength)
				: firstCouponCode;
		},
		showCouponList() {
			return this.mergeCouponCodes.length > 0;
		},
		totalCouponCodes() {
			return this.mergeCouponCodes.length;
		},
		isSingleCode() {
			return this.totalCouponCodes === 1;
		},
		codeSuffixLength() {
			if (this.totalCouponCodes > 0) {
				return (this.mergeCouponCodes[0].length - this.codePrefix.length);
			}

			return 0;
		},
		couponCodePattern() {
			return getECouponCodePattern(this.codePrefix, this.codeSuffixLength, this.mergeCouponCodes); // sample result is "BNNXXX"
		},
	},

	watch: {
		dataECoupon: {
			deep: true,
			handler(data) {
				// Watch props data change and update this
				this.formData.newCouponCodes = data.newCouponCodes || [];
				this.formData.couponCodes = data.couponCodes || [];
			},
		},
	},

	methods: {
		...mapActions({
			showToast: 'toast/showToast',
		}),
		importCouponCode() {
			const fileSelector = document.createElement('input');
			fileSelector.setAttribute('type', 'file');
			fileSelector.setAttribute('accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel');
			fileSelector.onchange = async () => {
				const file = fileSelector.files.item(0);
				const params = {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				};

				try {
					const res = await importCouponCodesAPI(file, params);
					if (!this.isEditMode) {
						this.formData.couponCodes = [...res?.data?.data] || [];
					} else {
						this.formData.newCouponCodes = [...res?.data?.data] || [];
					}
					this.showToast({
						content: 'Coupon codes successfully uploaded.',
						header: 'Success',
						type: 'success',
					});
				} catch (e) {
					this.showToast({
						content: 'Coupon codes upload failed',
						header: 'Failure',
						type: 'danger',
					});
				} finally {
					const couponCodes = this.isEditMode ? this.formData.newCouponCodes : this.formData.couponCodes;
					if (couponCodes && couponCodes.length) {
						const onlyNewCouponCodes = this.isEditMode ? this.formData.newCouponCodes.filter((code) => !this.formData.couponCodes.includes(code)) : [];
						const data = {
							prefix: this.codePrefix,
							length: this.codeSuffixLength,
							quantity: this.mergeCouponCodes.length,
							couponCodes: this.mergeCouponCodes, // Need to keep current pattern
							newCouponCodes: onlyNewCouponCodes,
						};
						this.$emit('update:ecoupon', data);
					}
				}
			};
			fileSelector.click();
		},
	},
};
</script>

<style lang="scss" scoped>
	.coupon-codes {
		border-radius: rem(4);
		border: rem(1) solid $color-gray-400;
		background: $color-white;
	}
</style>
