<template>
	<div>
		<CModal
			:show.sync="isShow"
			:close-on-backdrop="false"
			centered
		>
			<template #header-wrapper>
				<ModalHeaderWrapper
					title="Select Payment method"
					@onClose="close"
				/>
			</template>
			<template #default>
				<CRow>
					<CCol>
						<FormGetPaymentMethod
							ref="payment-method-form"
							:selected-list="selectedList"
							@onChange="handleListChange"
						>
							<template #no-item>
								<div
									class="text-center mt-5 mb-5"
									data-test-id="no-item"
								>
									<div class="typo-body-1 font-weight-bolder color-black-45">
										Search not found
									</div>
									<div class="typo-body-2 color-black-45">
										We didn't find any payment method matching your criteria
									</div>
								</div>
							</template>
						</FormGetPaymentMethod>
					</CCol>
				</CRow>
			</template>
			<template #footer>
				<BaseModalFooter
					:total-select="selectedList.length"
					@onCancel="close"
					@onConfirm="handleSubmit"
				/>
			</template>
		</CModal>
	</div>
</template>


<script>
import ModalHeaderWrapper from '@/components/ModalHeaderWrapper.vue';
import BaseModalFooter from '@/components/BaseModalFooter.vue';
import FormGetPaymentMethod from '@/components/FormGetPaymentMethod.vue';

export default {
	name: 'ModalPaymentMethod',
	components: {
		ModalHeaderWrapper,
		BaseModalFooter,
		FormGetPaymentMethod,
	},
	props: {
		list: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			isShow: false,
			selectedList: [...this.list],
		};
	},
	methods: {
		open() {
			this.isShow = true;
			this.resetState();
		},
		close() {
			this.isShow = false;
		},
		resetState() {
			const originalList = [...this.list];
			this.selectedList = originalList;
			this.$refs['payment-method-form'].resetState(originalList);
		},
		handleListChange(list) {
			this.selectedList = list;
		},
		handleSubmit() {
			this.isShow = false;
			this.$emit('onConfirm', this.selectedList);
		},
	},
};
</script>
