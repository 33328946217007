<template>
	<CRow v-if="discountType">
		<CCol v-if="isFreebie">
			<PromotionDiscountFreebie
				:products="formData.discountItemList"
				@update:products="handleDiscountItemListChange"
			/>
		</CCol>
		<CCol v-else-if="isTypeCoupon">
			<PromotionDiscountCoupon
				:coupons="formData.discountItemList"
				@update:coupons="handleDiscountItemListChange"
			/>
		</CCol>
		<template v-else>
			<CCol v-if="!isFreeShipping" md="6">
				<BaseInputNumber
					v-model="$v.formData.discount.$model"
					:decimal-scale="2"
					:is-valid="!$v.formData.discount.$error"
					:invalid-feedback="discountErrors"
					:append-text="discountAppendText"
					:placeholder="discountPlaceholderText"
					label="Discount value*"
					text-align="left"
				/>
			</CCol>
			<CCol v-if="isPercentageDiscount" md="6">
				<BaseInputNumber
					v-model="$v.formData.maximumDiscount.$model"
					:decimal-scale="2"
					:is-valid="!$v.formData.maximumDiscount.$error"
					:invalid-feedback="$t('global.error.required')"
					allow-null-value
					label="Maximum discount"
					append-text="฿"
					text-align="left"
					placeholder="Type maximum discount amount"
				/>
			</CCol>
			<CCol v-if="isOnTopPayment" md="12">
				<PromotionDisCountOnTopPayment
					:on-top-payments="formData.onTopPaymentList"
					@update:onTopPayments="handleOnTopPaymentListChange"
				/>
			</CCol>
		</template>
	</CRow>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, maxValue, minValue, minLength, requiredIf } from 'vuelidate/lib/validators';


import PromotionDiscountFreebie from '@/components/PromotionDiscountFreebie.vue';
import PromotionDiscountCoupon from '@/components/PromotionDiscountCoupon.vue';
import PromotionDisCountOnTopPayment from '@/components/PromotionDiscountOnTopPayment.vue';

import { DISCOUNT_TYPE_KEYS } from '../enums/promotions';

export default {
	name: 'PromotionDiscountValue',

	components: {
		PromotionDiscountFreebie,
		PromotionDiscountCoupon,
		PromotionDisCountOnTopPayment,
	},

	validations() {
		let discountRule = {
			required,
			greaterThanZero: (value) => {
				return value > 0;
			},
		};
		const maximumDiscountRule = {};
		let discountItemListRule = {};
		let onTopPaymentListRule = {};

		if (this.isPercentageDiscount) {
			discountRule = {
				...discountRule,
				maxValue: maxValue(100),
			};
		}

		if (this.isFixedAmountDiscount || this.isFixedAmountDiscountOnTopPayment) {
			discountRule = {
				required,
				minValue: minValue(0),
			};
		}

		if (this.isFreebie) {
			discountRule = {};
			discountItemListRule = { required };
		}

		if (this.isTypeCoupon) {
			discountRule = {};
			discountItemListRule = {
				required,
				$each: {
					status: { required },
					emailTemplateId: { required },
				},
			};
		}

		if (this.isFreeShipping) {
			discountRule = {};
			discountItemListRule = {};
		}

		if (this.isPercentageDiscountOnTopPayment || this.isFixedAmountDiscountOnTopPayment) {
			onTopPaymentListRule = {
				required,
				minLength: minLength(1),
				$each: {
					paymentMethod: {
						required,
					},
					paymentChannel: {
						required: requiredIf((vm) => {
							return vm?.paymentMethod?.channels?.length > 0;
						}),
					},
					campaignCode: {},
				},
			};
		}

		return {
			formData: {
				discount: discountRule,
				maximumDiscount: maximumDiscountRule,
				discountItemList: discountItemListRule,
				onTopPaymentList: onTopPaymentListRule,

			},
		};
	},

	mixins: [validationMixin],

	props: {
		defaultData: {
			type: Object,
			default: null,
		},
		discountType: {
			type: Object,
			default: null,
		},
	},

	data() {
		const {
			discount = null,
			maximumDiscount = null,
			discountItemList = [],
			onTopPaymentList = [],
		} = this.defaultData || {};

		return {
			formData: {
				discount,
				maximumDiscount,
				discountItemList,
				onTopPaymentList,
			},
		};
	},

	computed: {
		discountPlaceholderText() {
			return this.isFixedPriceDiscount
				? 'Type Fixed price'
				: 'Type discount amount';
		},
		discountAppendText() {
			if (!this.discountType || !this.discountType.value) {
				return '';
			}

			if (this.isPercentageDiscount) {
				return '%';
			}

			return '฿';
		},
		discountErrors() {
			if (!this.$v.formData.discount.required) {
				return this.$t('global.error.required');
			}

			if (!this.$v.formData.discount.greaterThanZero) {
				return 'Value must be greater than 0';
			}

			if (!this.$v.formData.discount.maxValue) {
				return this.$t('global.error.maximumPercentage');
			}

			return null;
		},
		isPercentageDiscount() {
			return this.discountType?.value === DISCOUNT_TYPE_KEYS.PERCENTAGE_DISCOUNT ||
				this.isPercentageDiscountOnTopPayment;
		},
		isFixedAmountDiscount() {
			return this.discountType?.value === DISCOUNT_TYPE_KEYS.FIXED_AMOUNT_DISCOUNT ||
				this.isFixedAmountDiscountOnTopPayment;
		},
		isFixedPriceDiscount() {
			return (
				this.discountType &&
				this.discountType.value === DISCOUNT_TYPE_KEYS.FIXED_PRICE
			);
		},
		isTypeFreebie() {
			return (
				this.discountType &&
				this.discountType.value === DISCOUNT_TYPE_KEYS.FREEBIE
			);
		},
		isTypeBundle2Freebie() {
			return (
				this.discountType &&
				this.discountType.value === DISCOUNT_TYPE_KEYS.BUNDLE2_SET_FREEBIE
			);
		},
		isTypeCoupon() {
			return (
				this.discountType &&
				this.discountType.value === DISCOUNT_TYPE_KEYS.COUPON
			);
		},
		isFreeShipping() {
			return (
				this.discountType &&
				this.discountType.value === DISCOUNT_TYPE_KEYS.FREE_SHIPPING
			);
		},
		isFreebie() {
			return this.isTypeFreebie || this.isTypeBundle2Freebie;
		},
		isPercentageDiscountOnTopPayment() {
			return this.discountType?.value === DISCOUNT_TYPE_KEYS.PERCENTAGE_DISCOUNT_ON_TOP_PAYMENT;
		},
		isFixedAmountDiscountOnTopPayment() {
			return this.discountType?.value === DISCOUNT_TYPE_KEYS.FIXED_AMOUNT_DISCOUNT_ON_TOP_PAYMENT;
		},
		isOnTopPayment() {
			return this.isPercentageDiscountOnTopPayment || this.isFixedAmountDiscountOnTopPayment;
		},
	},

	watch: {
		defaultData: {
			deep: true,
			handler(data) {
				const {
					discount = null,
					maximumDiscount = null,
					discountItemList = [],
					onTopPaymentList = [],
				} = data || {};

				this.formData = {
					discount,
					maximumDiscount,
					discountItemList,
					onTopPaymentList,
				};
			},
		},
		formData: {
			deep: true,
			handler(data) {
				this.$emit('update:is-valid', {
					valid: !this.$v.$invalid,
					data,
				});
			},
		},
	},

	created() {
		this.$emit('update:is-valid', {
			valid: !this.$v.$invalid,
			data: this.formData,
		});
	},

	methods: {
		handleDiscountItemListChange(list) {
			this.formData.discountItemList = list;
		},

		handleOnTopPaymentListChange(list) {
			this.formData.onTopPaymentList = list;
		},
	},
};
</script>
