<template>
	<div data-test-id="sku-modal">
		<CForm class="d-flex align-items-start" @submit.prevent="handleSubmit">
			<CInput
				v-model.trim="inputSKU"
				:is-valid="!isNotFound && !isDuplicated && isValid && null"
				:label="label"
				:placeholder="placeholder"
				class="flex-fill"
				add-input-classes="form-get-sku-input"
				data-test-id="input-sku"
				@input="handleChange"
			>
				<template #invalid-feedback>
					<div
						v-if="isNotFound"
						class="invalid-feedback"
						data-test-id="error-item-not-found"
					>
						We can't find this SKU in the system
					</div>
					<div
						v-else-if="isDuplicated"
						class="invalid-feedback"
						data-test-id="error-item-duplicated"
					>
						This SKU is already added, please try another.
					</div>
					<div
						v-else-if="!isValid"
						class="invalid-feedback"
						data-test-id="error-item-invalid"
					>
						{{ invalidFeedback }}
					</div>
				</template>
			</CInput>
			<CButton
				:disabled="isSubmitting"
				type="submit"
				class="btn-add ml-2"
				color="secondary"
				data-test-id="add-sku-btn"
			>
				Add SKU
			</CButton>
		</CForm>
		<slot
			v-if="!skuList.length"
			name="no-item"
		>
		</slot>
		<ul
			v-else
			class="list-item"
			:class="{ 'show-border': hasBorder }"
		>
			<slot name="list"></slot>
		</ul>
	</div>
</template>

<script>
import { getProductBySKUAPI } from '../services/api/sku.api';
import { transformedProduct } from '../assets/js/transform/products';

export default {
	name: 'FormGetFreebieProductBySKU',
	props: {
		label: {
			type: String,
			default: null,
		},
		originalSkuList: {
			type: Array,
			default: () => [],
		},
		skuList: {
			type: Array,
			default: () => [],
		},
		hasBorder: {
			type: Boolean,
			default: false,
		},
		isValid: {
			type: Boolean,
			default: true,
		},
		invalidFeedback: {
			type: String,
			default: null,
		},
		placeholder: {
			type: String,
			default: 'Paste or type SKU number here',
		},
	},
	data() {
		return {
			isSubmitting: false,
			isNotFound: false,
			isDuplicated: false,
			inputSKU: '',
		};
	},
	methods: {
		resetState() {
			this.inputSKU = '';
			this.isNotFound = false;
			this.isDuplicated = false;
		},
		handleChange() {
			this.isNotFound = false;
			this.isDuplicated = false;
		},
		handleRemove(sku) {
			this.resetState();
			const foundProduct = this.skuList.find((product) => product.sku === sku);
			this.$emit('onRemove', foundProduct);
		},
		handleReset() {
			this.resetState();
			this.$emit('update:skuList', this.originalSkuList);
			this.close();
		},
		async handleSubmit() {
			const exists = this.skuList.find((product) => product.sku.toUpperCase() === this.inputSKU.toUpperCase());
			if (exists) {
				this.isDuplicated = true;
				return;
			}

			this.isNotFound = false;
			this.isSubmitting = true;

			try {
				const params = {
					q: this.inputSKU,
					is_allowed_freebie: true,
				};
				const { data } = await getProductBySKUAPI(params);
				this.$emit('onGetProduct', [
					transformedProduct(data.data),
					...this.skuList,
				]);
				this.resetState();
			} catch {
				this.isNotFound = true;
			} finally {
				this.isSubmitting = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	::v-deep .input-group-text {
		padding-right: 0;
	}

	::v-deep .form-get-sku-input {
		margin-right: rem(100);
	}

	.btn-add {
		min-width: rem(84);
	}

	.list-item {
		padding: rem(20) rem(16) 0 rem(16);
		max-height: rem(320);
		overflow: auto;
	}

	.show-border {
		border: solid 1px $color-gray-400;
		border-radius: rem(4);
	}
</style>
