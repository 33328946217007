<template>
	<div>
		<CModal
			:show.sync="isShow"
			:close-on-backdrop="false"
			centered
			size="lg"
		>
			<template #header-wrapper>
				<ModalHeaderWrapper title="Select freebie SKU" @onClose="close" />
			</template>
			<template #default>
				<CRow>
					<CCol>
						<FormGetFreebieProductBySKU
							ref="form-sku"
							:sku-list="list"
							@onGetProduct="handleGet"
							@onRemove="handleRemove"
						>
							<template #no-item>
								<div
									class="no-item d-flex text-center"
									data-test-id="no-item"
								>
									<div class="d-block">
										<div
											class="no-sku font-weight-bolder color-black-45"
										>
											No freebie SKU yet
										</div>
										<div class="typo-body-2 color-black-45">
											Add "SKU number" in order to set
											freebie SKU.
										</div>
									</div>
								</div>
							</template>
							<template #list>
								<div class="item-list">
									<CRow class="mb-2">
										<CCol md="1" class="font-weight-bold">
											SKUs
										</CCol>
										<CCol md="6" class="font-weight-bold" />
										<CCol md="2" class="font-weight-bold text-right">
											Stock qty.
										</CCol>
										<CCol md="2" class="font-weight-bold">
											Reward qty. *
										</CCol>
									</CRow>
									<SKUWithStockListItem
										v-for="item in formList"
										:id="item.id"
										:key="item.id"
										:thumbnail="item.thumbnail"
										:name="item.name"
										:sku="item.sku"
										:stock-qty="item.stockAvailable"
										:reward-qty="item.rewardQty"
										@onRemove="handleRemove(item.sku)"
										@update:rewardQty="handleChangeRewardQty"
									/>
								</div>
							</template>
						</FormGetFreebieProductBySKU>
					</CCol>
				</CRow>
			</template>
			<template #footer>
				<BaseModalFooter
					:disabled-confirm="!isFormValid"
					@onCancel="handleCancel"
					@onConfirm="handleSubmit"
				/>
			</template>
		</CModal>
	</div>
</template>

<script>
import BaseModalFooter from '@/components/BaseModalFooter.vue';
import ModalHeaderWrapper from '@/components/ModalHeaderWrapper.vue';
import FormGetFreebieProductBySKU from '@/components/FormGetFreebieProductBySKU.vue';
import SKUWithStockListItem from '@/components/SKUWithStockListItem.vue';

import { cloneDeep } from '../assets/js/helpers';

export default {
	name: 'ModalSkuFreebie',
	components: {
		ModalHeaderWrapper,
		FormGetFreebieProductBySKU,
		SKUWithStockListItem,
		BaseModalFooter,
	},
	props: {
		products: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			isShow: false,
			list: [],
		};
	},
	computed: {
		formList() {
			return this.list && this.list.length ?
				this.list.map((item) => ({
					...item,
					valid: true,
				})) : null;
		},
		isFormValid() {
			if (this.list && this.list.length > 0) {
				return this.list.every((item) => item.valid);
			}

			return false;
		},
	},
	methods: {
		resetState() {
			this.list = cloneDeep(this.products);
			this.$refs['form-sku'].resetState();
		},
		open() {
			this.isShow = true;
			this.resetState();
		},
		close() {
			this.list = [];
			this.isShow = false;
		},
		handleGet(products) {
			this.list = products;
		},
		handleRemove(sku) {
			this.$refs['form-sku'].resetState();
			const itemIndex = this.list.findIndex((item) => `${item.sku}` === `${sku}`);
			if (itemIndex >= 0) {
				this.list.splice(itemIndex, 1);
			}
		},
		handleCancel() {
			this.close();
		},
		handleChangeRewardQty({ id, valid, value }) {
			const index = this.list.findIndex((item) => item.id === id);
			if (index >= 0) {
				this.$set(this.list[index], 'rewardQty', value);
				this.$set(this.list[index], 'valid', valid);
			}
		},
		handleSubmit() {
			this.$emit('update:products', this.list);
			this.close();
		},
	},
};
</script>

<style lang="scss" scoped>
	.no-item {
		min-height: rem(200);
		justify-content: center;
		align-items: center;
	}

	.item-list {
		min-height: rem(200);
	}

	::v-deep .input-group-text {
		padding-right: rem(12);
	}
</style>
