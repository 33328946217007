import { saveAs } from 'file-saver';
import httpClient from './httpClient';
import { getFilenameFromHeader } from '../../assets/js/helpers';

export const BACKOFFICE_ENDPOINT = '/backoffice';
export const EXPORT_ENDPOINT = `${BACKOFFICE_ENDPOINT}/exports`;

export const exportAPI = async (params) => {
	const { data, headers } = await httpClient.get(`${EXPORT_ENDPOINT}`, {
		responseType: 'blob',
		params: {
			key: params.key,
			category_id: params.category_id,
			is_active: params.is_active,
			is_eol: params.is_eol,
		},
	});

	const filename = getFilenameFromHeader(headers);
	saveAs(data, filename);
};

export const exportProductInfoAPI = (params = {}) => httpClient.get(`${EXPORT_ENDPOINT}/product-info`, {
	params: {
		q: params.q,
		category_id: params.category_id,
		is_active: params.is_active,
		is_eol: params.is_eol,
		is_assign_category: params.is_assign_category,
		brand_id: params.brand_id,
		type: params.type,
		is_new: params.is_new,
		selling_price_from: params.selling_price_from,
		selling_price_to: params.selling_price_to,
		created_from: params.created_from,
		created_to: params.created_to,
		is_list_visible: params.is_list_visible,
		is_search_visible: params.is_search_visible,
		is_detail_visible: params.is_detail_visible,
	},
});

export const exportProductInfoDynamicFieldAPI = (params = {}) => httpClient.get(`${EXPORT_ENDPOINT}/product-info-dynamic-field`, {
	params: {
		q: params.q,
		category_id: params.category_id,
		is_active: params.is_active,
		is_eol: params.is_eol,
		is_assign_category: params.is_assign_category,
		brand_id: params.brand_id,
		type: params.type,
		is_new: params.is_new,
		selling_price_from: params.selling_price_from,
		selling_price_to: params.selling_price_to,
		created_from: params.created_from,
		created_to: params.created_to,
		is_list_visible: params.is_list_visible,
		is_search_visible: params.is_search_visible,
		is_detail_visible: params.is_detail_visible,
	},
});

export const exportProductPriceAPI = (params = {}) => httpClient.get(`${EXPORT_ENDPOINT}/product-price`, {
	params: {
		q: params.q,
		category_id: params.category_id,
		is_active: params.is_active,
		is_eol: params.is_eol,
		is_assign_category: params.is_assign_category,
		brand_id: params.brand_id,
		type: params.type,
		is_new: params.is_new,
		selling_price_from: params.selling_price_from,
		selling_price_to: params.selling_price_to,
		created_from: params.created_from,
		created_to: params.created_to,
		is_list_visible: params.is_list_visible,
		is_search_visible: params.is_search_visible,
		is_detail_visible: params.is_detail_visible,
	},
});

export const exportProductGroupAPI = (params = {}) => httpClient.get(`${EXPORT_ENDPOINT}/product-group`, {
	params: {
		q: params.q,
		category_id: params.category_id,
		is_active: params.is_active,
		is_eol: params.is_eol,
		is_assign_category: params.is_assign_category,
		brand_id: params.brand_id,
		type: params.type,
		is_new: params.is_new,
		selling_price_from: params.selling_price_from,
		selling_price_to: params.selling_price_to,
		created_from: params.created_from,
		created_to: params.created_to,
		is_list_visible: params.is_list_visible,
		is_search_visible: params.is_search_visible,
		is_detail_visible: params.is_detail_visible,
	},
});

export const exportProductShortAttributeAPI = (params = {}) => httpClient.get(`${EXPORT_ENDPOINT}/product-short-attribute`, {
	params: {
		q: params.q,
		is_active: params.is_active,
		is_eol: params.is_eol,
		category_id: params.category_id,
		is_assign_category: params.is_assign_category,
		brand_id: params.brand_id,
		type: params.type,
		is_new: params.is_new,
		selling_price_from: params.selling_price_from,
		selling_price_to: params.selling_price_to,
		created_from: params.created_from,
		created_to: params.created_to,
		is_list_visible: params.is_list_visible,
		is_search_visible: params.is_search_visible,
		is_detail_visible: params.is_detail_visible,
	},
});

export const exportInstallmentPlanSkuAPI = async (planId) => {
	const { data, headers } = await httpClient.get(`${EXPORT_ENDPOINT}/installment-plans`, {
		responseType: 'blob',
		params: {
			installment_plan_id: planId,
		},
	});

	const filename = getFilenameFromHeader(headers);
	saveAs(data, filename);
};

export const exportECouponCodeAPI = async (promotionId) => {
	const { data, headers } = await httpClient.get(`${BACKOFFICE_ENDPOINT}/promotions/${promotionId}/codes`, {
		responseType: 'blob',
	});

	const filename = getFilenameFromHeader(headers);
	saveAs(data, filename);
};

export const exportUserInfoAPI = async (params) => {
	const { data, headers } = await httpClient.get(`${EXPORT_ENDPOINT}/users`, {
		responseType: 'blob',
		params,
	});

	const filename = getFilenameFromHeader(headers);
	saveAs(data, filename);
};

export const exportPreOrderPickupAtStoreAPI = async (ruleId) => {
	const { data, headers } = await httpClient.get(`${EXPORT_ENDPOINT}/pre-order-pickup-rule-skus`, {
		responseType: 'blob',
		params: {
			pre_order_pickup_rule_id: ruleId,
		},
	});

	const filename = getFilenameFromHeader(headers);
	saveAs(data, filename);
};

export const exportPreOrderPickupAtStoreReportAPI = (params = {}) => httpClient.get(`${EXPORT_ENDPOINT}/pre-order-pickup-report`, {
	params: {
		q: params.q,
		is_active: params.is_active,
		payment_option_type: params.payment_option_type,
		period_start_at_from: params.period_start_at_from,
		period_start_at_to: params.period_start_at_to,
		period_end_at_from: params.period_end_at_from,
		period_end_at_to: params.period_end_at_to,
		remaining_start_at_from: params.remaining_start_at_from,
		remaining_start_at_to: params.remaining_start_at_to,
		remaining_end_at_from: params.remaining_end_at_from,
		remaining_end_at_to: params.remaining_end_at_to,
		released_period_from: params.released_period_from,
		released_period_to: params.released_period_to,
		period_status: params.period_status,
		remaining_status: params.remaining_status,
		released_status: params.released_status,
	},
});

export const exportProductAppleCareAPI = (params = {}) => httpClient.get(`${EXPORT_ENDPOINT}/product-apple-care`, {
	params: {
		q: params.q,
		category_id: params.category_id,
		is_active: params.is_active,
		is_eol: params.is_eol,
		is_assign_category: params.is_assign_category,
		brand_id: params.brand_id,
		type: params.type,
		is_new: params.is_new,
		selling_price_from: params.selling_price_from,
		selling_price_to: params.selling_price_to,
		created_from: params.created_from,
		created_to: params.created_to,
		is_list_visible: params.is_list_visible,
		is_search_visible: params.is_search_visible,
		is_detail_visible: params.is_detail_visible,
	},
});

export const exportBackInStockInfoAPI = (params = {}) => httpClient.get(`${EXPORT_ENDPOINT}/back-in-stock-notifications`, {
	params: {
		start_date: params.start_date,
		end_date: params.end_date,
	},
});
