<template>
	<div v-if="label" class="code-label typo-body-1">
		<span class="label">{{ label }}</span>
	</div>
</template>

<script>
export default {
	props: {
		label: {
			type: String,
			default: '',
		},
	},
};
</script>

<style lang="scss" scoped>
	.code-label {
		position: relative;
		display: inline-block;

		margin-right: rem(16);
		padding: rem(2) rem(14) rem(2) rem(8);

		line-height: 1.5;
		color: $color-black;
		background-color: $color-primary;
		border-radius: rem(4);

		&::after {
			content: "";
			position: absolute;
			top: 50%;
			right: rem(-6);
			transform: translateY(-50%);

			width: rem(12);
			height: rem(12);
			background-color: $color-white;
			border-radius: 50%;
		}
	}
</style>