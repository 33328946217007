<template>
	<div>
		<h2 class="typo-h4 position-relative">
			Product bundle condition
			<CButton
				type="button"
				class="btn btn-add float-right"
				color="secondary"
				@click="$refs['modal-sku'].open()"
			>
				{{ products.length ? 'Edit' : 'Add' }} SKU
			</CButton>
		</h2>
		<p class="typo-caption color-black-45">
			Select al least 2 SKUs for bundle product. Limit 10 SKUs.
		</p>
		<CRow v-if="products.length" lg="12">
			<CCol>
				<CRow class="product-title">
					<CCol md="10">
						<span class="label">SKUs</span>
					</CCol>
					<CCol md="2" class="product-quantity">
						<span class="label pl-0">Qty.</span>
					</CCol>
				</CRow>
				<ul class="list-unstyled p-0 mt-1 mb-0">
					<li
						class="border rounded-sm border-secondary pt-3"
						data-test-id="product-group-sku"
					>
						<CRow
							v-for="product in products"
							:key="product.sku"
							class="typo-body-2 d-flex px-3 text-decoration-none"
						>
							<CCol md="10">
								<SKUListItem
									:thumbnail="product.thumbnail"
									:name="product.name"
									:sku="product.sku"
									:is-primary="product.isPrimary"
								/>
							</CCol>
							<CCol md="2" class="product-quantity">
								{{ product.quantity }}
							</CCol>
						</CRow>
					</li>
				</ul>
			</CCol>
		</CRow>
		<BaseNoItemBanner v-else text="There is no SKU added." />

		<ModalBundleSKU
			ref="modal-sku"
			title="Select bundle SKUs"
			:products="products"
			@update:skuList="handleChangeCondition"
		/>
	</div>
</template>

<script>
import ModalBundleSKU from '@/components/ModalBundleSKU.vue';
import SKUListItem from '@/components/SKUListItem.vue';
import { IMAGE_PLACEHOLDER_URL } from '../enums/general';

export default {
	name: 'PromotionProductBundleCondition',
	components: {
		ModalBundleSKU,
		SKUListItem,
	},
	props: {
		products: {
			type: Array,
			default: () => [],
		},
		title: {
			type: String,
			default: null,
		},
		description: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			IMAGE_PLACEHOLDER_URL,
		};
	},
	methods: {
		handleAdd() {
			this.$refs['modal-sku'].open();
		},
		handleChangeCondition(condition = []) {
			this.$emit(
				'onUpdate',
				condition,
			);
		},
	},
};
</script>

<style lang="scss" scoped>
.thumbnail {
	width: rem(40);
	height: rem(40);
	object-fit: contain;
}

.description {
	color: $color-black-45;
}

.product {
	&-title {
		padding: 0 rem(8);
	}

	&-quantity {
		text-align: center;
	}
}
</style>
