<template>
	<div class="form-container">
		<!-- General info -->
		<CRow>
			<CCol md="12">
				<h2 class="typo-h4 position-relative">
					General info
					<span class="title-caption typo-caption color-black-45">Promotion ID: <span class="typo-caption color-black-45">{{ formData.id || 'Generate after creates' }}</span></span>
				</h2>
			</CCol>
			<CCol md="12" class="mt-2">
				<CInput
					v-model.trim="$v.formData.name.$model"
					:is-valid="!$v.formData.name.$error && null"
					label="Promotion name (for back office)*"
					placeholder="e.g. Mid Year Sale"
					invalid-feedback="Field Length: 1 - 255 characters"
				/>
			</CCol>
			<CCol md="6" class="mt-2">
				<label class="d-block">Promotion status</label>
				<CInputRadioGroup
					:options="statusOptions"
					:checked="formData.status"
					:custom="true"
					:inline="true"
					class="radio-group"
					name="status"
					data-test-id="promotion-status"
					@update:checked="handleChangeStatus"
				/>
			</CCol>
			<CCol md="6" class="mt-2">
				<BaseInputNumber
					v-model="$v.formData.priority.$model"
					:is-valid="!$v.formData.priority.$error"
					invalid-feedback="Discount calculation priority is required"
					placeholder="0"
					label="Discount calculation priority (Cart)*"
					text-align="left"
				/>
			</CCol>
			<CCol v-if="isTypeECoupon" class="pt-4 d-none">
				<CRow class="mapping-sku-box m-0">
					<CCol md="6" class="p-3">
						<h3 class="typo-label">Mapping SKU from ITEC</h3>
						<p class="typo-caption color-black-45 mb-0">This will help displaying product title on Storefront when using this promotion as a reward for another promotion.</p>
					</CCol>
					<CCol md="6" class="p-3 text-right">
						<CButton
							color="secondary"
							class="btn btn-secondary"
							@click="$refs['modal-sku'].open()"
						>
							{{ mappingSKUButtonText }}
						</CButton>
					</CCol>
					<CCol v-if="formData.mappingProduct" md="12">
						<SKUListItem
							:thumbnail="formData.mappingProduct.thumbnail"
							:name="formData.mappingProduct.name"
							:sku="formData.mappingProduct.sku"
						/>
					</CCol>
				</CRow>
			</CCol>
			<CCol md="12" class="mt-4">
				<hr>
			</CCol>
		</CRow>
		<!-- End General info -->

		<!-- Active -->
		<CRow class="form-group mt-4">
			<CCol md="12">
				<h2 class="typo-h4">
					Active dates
				</h2>
			</CCol>
			<CCol md="6" class="mt-2">
				<BaseInputDate
					v-model="$v.formData.startDate.$model"
					:disabled="!isEnableStartActiveDate"
					:is-valid="!$v.formData.startDate.$error"
					:min-date="minActiveDate"
					label="Start date*"
					invalid-feedback="Start date is required"
					placeholder="Select date"
					@input="handleActiveStartDateChange"
				>
					<template #append-content>
						<CIcon class="cil-calendar" name="cil-calendar" />
					</template>
				</BaseInputDate>
			</CCol>
			<CCol md="6" class="mt-2">
				<BaseInputTime
					v-model="$v.formData.startTime.$model"
					:disabled="!isEnableStartActiveDate"
					:is-valid="!$v.formData.startTime.$error"
					:invalid-feedback="errorStartTime"
					label="Start time"
				/>
			</CCol>

			<CCol md="6" class="mt-3">
				<BaseInputDate
					v-model="$v.formData.endDate.$model"
					:disabled="!isEnableEndActiveDate || $v.formData.startDate.$invalid"
					:is-valid="!$v.formData.endDate.$error"
					:min-date="formData.startDate"
					label="End date*"
					invalid-feedback="End date is required"
					placeholder="Select date"
					data-test-id="end-date"
				>
					<template #append-content>
						<CIcon class="cil-calendar" name="cil-calendar" />
					</template>
				</BaseInputDate>
			</CCol>
			<CCol md="6" class="mt-3">
				<BaseInputTime
					v-model="$v.formData.endTime.$model"
					:disabled="!isEnableEndActiveDate"
					:is-valid="!$v.formData.endTime.$error"
					:invalid-feedback="errorEndTime"
					label="End time"
				/>
			</CCol>

			<CCol md="12" class="mt-4">
				<hr>
			</CCol>
		</CRow>
		<!-- End Active -->

		<!-- Usage limit -->
		<CRow class="form-group mt-4">
			<CCol md="12">
				<h2 class="typo-h4">
					Usage limit
				</h2>
			</CCol>
			<CCol md="6" class="mt-2">
				<label>Usage limit per promotion</label>
				<BaseInputNumber
					v-model="formData.usageLimitPerPromotion"
					text-align="left"
					placeholder="Unlimited usage"
				/>
			</CCol>
			<CCol md="6" class="mt-2">
				<label>Usage limit per user</label>
				<BaseInputNumber
					v-model="formData.usageLimitPerUser"
					text-align="left"
					placeholder="Unlimited usage"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol md="12" class="my-4">
				<hr>
			</CCol>
		</CRow>
		<!-- End Usage limit -->

		<!-- Override payment period -->
		<CRow class="form-group mt-2">
			<CCol>
				<div class="label mt-3">
					Override payment order expire period
				</div>
			</CCol>
			<CCol md="3">
				<div class="form-group-switch mt-3">
					<CSwitch
						:checked.sync="formData.overridePeriod"
						variant="3d"
						size="sm"
						class="switch-custom"
						color="success"
					/>
					<span class="typo-body-2">
						{{ formData.overridePeriod ? 'On' : 'Off' }}
					</span>
				</div>
			</CCol>
			<CCol md="12" class="mt-3">
				<div
					v-if="formData.overridePeriod"
					class="bg-gray-100 color-black-45 p-4"
				>
					<CRow>
						<CCol md="6">
							<BaseInputNumber
								v-model="$v.formData.overridePeriodValue.$model"
								:is-valid="!$v.formData.overridePeriodValue.$error"
								invalid-feedback="Value must be greater than 0"
								text-align="left"
								placeholder="Expire in"
								label="Expires in*"
								append-text="hr."
							/>
						</CCol>
					</CRow>
				</div>
			</CCol>
			<CCol md="12" class="mt-4">
				<hr>
			</CCol>
		</CRow>
		<!-- Override payment period -->

		<!-- Customer group -->
		<CRow>
			<CCol md="12">
				<PromotionCustomerGroup
					:customer-groups="formData.customerGroups"
					@update:customerGroups="handleCustomerGroupChange"
				/>
			</CCol>
		</CRow>
		<!-- End Customer group -->

		<!-- Exclude promotion condition -->
		<CRow>
			<CCol md="12">
				<PromotionExcludePromotionCondition
					:promotions="formData.excludePromotions"
					:exclude-ids="[formData.id]"
					@update:excludePromotions="handleExcludePromotionChange"
				/>
			</CCol>
		</CRow>
		<!-- End Exclude promotion condition -->

		<!-- Remarks -->
		<CRow v-if="isEditMode">
			<CCol md="12">
				<PromotionRemarks
					:remarks.sync="formData.remarks"
					:promotion-id="formData.id"
				/>
			</CCol>
		</CRow>
		<!-- End Remarks -->

		<ModalSingleSKU
			ref="modal-sku"
			:product="formData.mappingProduct"
			title="Add SKU"
			@onConfirm="handleMappingProductChange"
		/>
	</div>
</template>

<script>
import dayjs from 'dayjs';
import { required, maxLength, minValue } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';

import PromotionCustomerGroup from '@/components/PromotionCustomerGroup.vue';
import PromotionExcludePromotionCondition from '@/components/PromotionExcludePromotionCondition.vue';
import PromotionRemarks from '@/components/PromotionRemarks.vue';
import ModalSingleSKU from '@/components/ModalSingleSKU.vue';
import SKUListItem from '@/components/SKUListItem.vue';

import {
	PROMOTION_STATUSES,
	PROMOTION_STATUS_OPTIONS,
	PERIOD_STATUSES,
	PROMOTION_TYPES,
} from '../enums/promotions';

export default {
	name: 'PromotionStepGeneralInfo',

	components: {
		PromotionCustomerGroup,
		PromotionExcludePromotionCondition,
		PromotionRemarks,
		ModalSingleSKU,
		SKUListItem,
	},

	validations() {
		let schema = {};
		const base = {
			name: {
				required,
				maxLength: maxLength(255),
			},
			priority: {
				required,
			},
			startDate: {
				required,
			},
			endDate: {
				required,
			},
			startTime: {
				required,
				validTime(time) {
					return time.length === 5;
				},
			},
			endTime: {
				required,
				validTime(time) {
					return time.length === 5;
				},
			},
			customerGroups: {
				required,
			},
			...(
				this.formData.overridePeriod
					? {
						overridePeriodValue: {
							required,
							minValue: minValue(1),
						},
					}
					: null
			),
		};

		// If start date same as end date, we need to validation time.
		const isSameDateSchema = {
			endTime: {
				required,
				validTime(time) {
					return time.length === 5;
				},
				after(time, vm) {
					const [timeFromHour = 0, timeFromMinute = 0] = vm.startTime.split(':');
					const [timeToHour = 0, timeToMinute = 0] = vm.endTime.split(':');
					const timeFrom = dayjs().hour(timeFromHour).minute(timeFromMinute);
					const timeTo = dayjs().hour(timeToHour).minute(timeToMinute);
					return timeFrom.isBefore(timeTo);
				},
			},
		};

		if (this.formData.startDate && this.formData.endDate && dayjs(this.formData.startDate).isSame(dayjs(this.formData.endDate))) {
			schema = {
				formData: {
					...base,
					...isSameDateSchema,
				},
			};
		} else {
			schema = {
				formData: {
					...base,
				},
			};
		}

		return schema;
	},

	mixins: [validationMixin],

	props: {
		defaultData: {
			type: Object,
			default: null,
		},
		isEditMode: {
			type: Boolean,
			default: false,
		},
		promotionType: {
			type: String,
			default: PROMOTION_TYPES.GENERAL,
		},
	},

	data() {
		const {
			id,
			name = null,
			priority = 10,
			mappingProduct = null,
			status = PROMOTION_STATUSES.INACTIVE,
			startDate = null,
			startTime = '00:00',
			endDate = null,
			endTime = '23:59',
			periodStatus,
			overridePeriod = false,
			overridePeriodValue = null,
			usageLimitPerPromotion,
			usageLimitPerUser,
			customerGroups = [],
			excludePromotions = [],
			remarks,
		} = this.defaultData || {};

		return {
			statusOptions: PROMOTION_STATUS_OPTIONS,
			minActiveDate: new Date(),
			periodStatus,
			formData: {
				id,
				name,
				priority,
				mappingProduct,
				status,
				startDate,
				startTime,
				endDate,
				endTime,
				usageLimitPerPromotion,
				usageLimitPerUser,
				overridePeriod,
				overridePeriodValue,
				customerGroups,
				excludePromotions,
				remarks,
			},
		};
	},

	computed: {
		errorStartTime() {
			if (!this.$v.formData.startTime.required) {
				return 'Required field';
			}

			if (!this.$v.formData.startTime.validTime) {
				return 'Time not valid';
			}

			return null;
		},
		errorEndTime() {
			if (!this.$v.formData.endTime.required) {
				return 'Required field';
			}

			if (!this.$v.formData.endTime.validTime) {
				return 'Time not valid';
			}

			if (!this.$v.formData.endTime.after) {
				return `Should be after ${this.formData.startTime}`;
			}

			return null;
		},
		isEnableStartActiveDate() {
			return ![PERIOD_STATUSES.ON_GOING, PERIOD_STATUSES.EXPIRED].includes(this.periodStatus);
		},
		isEnableEndActiveDate() {
			return ![PERIOD_STATUSES.EXPIRED].includes(this.periodStatus);
		},
		isTypeECoupon() {
			return this.promotionType === PROMOTION_TYPES.COUPON;
		},
		mappingSKUButtonText() {
			return this.formData && this.formData.mappingProduct ? 'Edit SKU' : 'Add SKU';
		},
	},

	watch: {
		formData: {
			deep: true,
			handler(data) {
				this.$emit(
					'update:is-valid',
					{
						valid: !this.$v.$invalid,
						data,
					},
				);
			},
		},
	},

	methods: {
		handleChangeStatus(value) {
			this.formData.status = value;
		},

		handleActiveStartDateChange(value) {
			if (!this.formData.endDate || dayjs(value).isAfter(dayjs(this.formData.endDate))) {
				this.formData.endDate = value;
			}
		},

		handleCustomerGroupChange(list) {
			this.formData.customerGroups = list;
		},

		handleExcludePromotionChange(list) {
			this.formData.excludePromotions = list;
		},

		handleMappingProductChange(product) {
			this.formData.mappingProduct = product;
		},
	},
};
</script>

<style lang="scss" scoped>
	.form-container {
		margin-top: rem(80);

		.title-caption {
			position: absolute;
			right: 0;
			bottom: rem(4);
			font-weight: $font-weight-semi-bold;

			span {
				font-weight: $font-weight-bold;
			}
		}
	}

	.mapping-sku-box {
		border-radius: rem(4);
		border: solid rem(1) $color-gray-400;
	}
</style>
