<template>
	<CRow class="mt-3 mb-4">
		<CCol>
			<div class="d-flex mb-3 justify-content-between">
				<div class="mb-0">
					<h6 data-test-id="title">
						Freebie SKU*
					</h6>
					<div
						class="typo-caption color-black-45"
						data-test-id="description"
					>
						Set one or more freebie SKUs for this promotion
					</div>
				</div>
				<div class="btn-add-wrapper">
					<CButton
						type="button"
						class="btn btn-add"
						color="secondary"
						data-test-id="button-add"
						@click="$refs['modal-sku'].open()"
					>
						{{ `${products.length ? 'Edit' : 'Add'} freebie SKU` }}
					</CButton>
				</div>
			</div>
			<CRow>
				<CCol v-if="!products.length" class="mb-3" lg="12">
					<BaseNoItemBanner
						:class="{ 'is-invalid': !isValid }"
						text="There is no freebie SKU added."
					/>
					<div v-if="invalidFeedback" class="invalid-feedback">
						{{ invalidFeedback }}
					</div>
				</CCol>
				<CCol v-else lg="12">
					<CRow class="mb-2">
						<CCol md="8">
							<span class="label">SKUs</span>
						</CCol>
						<CCol md="2">
							<span class="label text-right">Stock qty.</span>
						</CCol>
						<CCol md="2">
							<span class="label">Reward qty.</span>
						</CCol>
					</CRow>
					<ul class="list-unstyled p-0">
						<li
							v-for="product in products"
							:key="product.sku"
							class="border rounded-sm border-secondary pt-3 mb-3"
						>
							<CRow
								class="typo-body-2 d-flex px-3 text-decoration-none"
							>
								<CCol md="8">
									<SKUListItem
										:thumbnail="product.thumbnail"
										:name="product.name"
										:sku="product.sku"
										:is-primary="product.isPrimary"
									/>
								</CCol>
								<CCol md="2" class="item-quantity text-right">
									{{ product.stockAvailable | numberFormat }}
								</CCol>
								<CCol md="2" class="item-quantity text-right">
									{{ product.rewardQty | numberFormat }}
								</CCol>
							</CRow>

							<PromotionDiscountFreebieStockOptions
								:default-data="product.stockOption"
								@update:is-valid="handleValidChange($event, product)"
							/>
						</li>
					</ul>
				</CCol>
			</CRow>
		</CCol>

		<ModalSkuFreebie
			ref="modal-sku"
			:products="products"
			@update:products="handleProductListChange"
		/>
	</CRow>
</template>

<script>
import ModalSkuFreebie from '@/components/ModalSkuFreebie.vue';
import SKUListItem from '@/components/SKUListItem.vue';
import PromotionDiscountFreebieStockOptions from '@/components/PromotionDiscountFreebieStockOptions.vue';
import { IMAGE_PLACEHOLDER_URL } from '../enums/general';

export default {
	name: 'PromotionDiscountFreebie',
	components: {
		ModalSkuFreebie,
		SKUListItem,
		PromotionDiscountFreebieStockOptions,
	},
	props: {
		products: {
			type: Array,
			default: () => [],
		},
		invalidFeedback: {
			type: String,
			default: null,
		},
		isValid: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			IMAGE_PLACEHOLDER_URL,
		};
	},
	methods: {
		handleProductListChange(list) {
			this.$emit('update:products', list);
		},

		handleValidChange(value, updatedProduct) {
			const { valid, data: stockOption } = value;

			const updatedList = this.products.map((product) => {
				if (product.id === updatedProduct.id) {
					return {
						...product,
						stockOption,
						valid,
					};
				}

				return product;
			});

			this.$emit('update:products', updatedList);
		},
	},
};
</script>
