<template>
	<div>
		<CModal
			:show.sync="isShow"
			:close-on-backdrop="false"
			centered
			size="lg"
		>
			<template #header-wrapper>
				<ModalHeaderWrapper
					:title="`${isEdit ? 'Edit' : 'Add'} tier value`"
					@onClose="close"
				/>
			</template>
			<template #default>
				<CRow>
					<CCol md="1">
						<strong>Tier</strong>
					</CCol>
					<CCol md="3">
						<strong>{{ minimumText }}*</strong>
					</CCol>
					<CCol md="3">
						<strong>Usage limit per tier</strong>
					</CCol>
					<CCol md="3">
						<strong>Usage limit per user</strong>
					</CCol>
				</CRow>
				<CRow
					v-for="(validateItem, index) in $v.localList.$each.$iter"
					:key="`params${index}`"
					class="mt-1"
				>
					<CCol md="1" class="pt-2">
						<strong>
							{{ Number(index) + 1 }}
						</strong>
					</CCol>
					<CCol md="3">
						<BaseInputNumber
							v-model.trim="validateItem.minimumPurchaseAmount.$model"
							:is-valid="!validateItem.minimumPurchaseAmount.$error"
							:invalid-feedback="minimumValueErrors(validateItem.minimumPurchaseAmount)"
							:decimal-scale="isCartMinimumTypePurchaseAmount ? 2 : null"
							:prepend-text="isCartMinimumTypePurchaseAmount ? '฿' : null"
							type="number"
							text-align="right"
							placeholder="0"
						/>
					</CCol>
					<CCol md="3">
						<BaseInputNumber
							v-model.trim="validateItem.usageLimitPerTier.$model"
							type="text"
							placeholder="Unlimited usage"
						/>
					</CCol>
					<CCol md="3">
						<BaseInputNumber
							v-model.trim="validateItem.usageLimitPerUser.$model"
							type="text"
							placeholder="Unlimited usage"
						/>
					</CCol>
					<CCol v-if="index > 1" md="1" class="pt-1">
						<CButton
							class="btn-delete p-0"
							@click.prevent="handleRemoveValue(index)"
						>
							<CIcon class="icon-trash" name="cil-trash" />
						</CButton>
					</CCol>
				</CRow>
				<CRow md="2">
					<CCol>
						<a
							href="#"
							class="btn-add-new"
							@click.prevent="handleAddNewValue"
						>
							Add new tier
						</a>
					</CCol>
				</CRow>
			</template>
			<template #footer>
				<CButton
					class="modal-button transparent mr-3"
					color="tertiary"
					data-test-id="cancel-button"
					@click="close"
				>
					Cancel
				</CButton>
				<CButton
					:disabled="$v.$invalid"
					class="modal-button transparent"
					color="primary"
					data-test-id="primary-button"
					@click="handleConfirm"
				>
					Done
				</CButton>
			</template>
		</CModal>
	</div>
</template>

<script>
import findLastIndex from 'lodash/findLastIndex';
import { required, minLength } from 'vuelidate/lib/validators';
import ModalHeaderWrapper from '@/components/ModalHeaderWrapper.vue';
import { CART_CONDITION_TYPES } from '../enums/promotions';

import { cloneDeep, parseNumber } from '../assets/js/helpers';

export default {
	name: 'ModalTierValue',
	components: {
		ModalHeaderWrapper,
	},
	validations() {
		return {
			localList: {
				required,
				minLength: minLength(2),
				$each: {
					id: {},
					minimumPurchaseAmount: {
						required,
						greaterThanPreviousTier: (value, item) => {
							if (this.localList && this.localList.length > 1) {
								const tierIndex = findLastIndex(this.localList, (tier) => tier.id === item.id);
								if (tierIndex > 0) {
									return parseNumber(value) > parseNumber(this.localList[tierIndex - 1].minimumPurchaseAmount);
								}
							}
							return true;
						},
					},
					usageLimitPerTier: {},
					usageLimitPerUser: {},
				},
			},
		};
	},
	props: {
		isEdit: {
			type: Boolean,
			default: false,
		},
		defaultData: {
			type: Array,
			default: null,
		},
		cartMinimumType: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			isShow: false,
			localList: null,

			defaultTierValue: {
				minimumPurchaseAmount: null,
				usageLimitPerTier: null,
				usageLimitPerUser: null,
			},
		};
	},
	computed: {
		isCartMinimumTypePurchaseAmount() {
			return this.cartMinimumType && this.cartMinimumType.value === CART_CONDITION_TYPES.PURCHASE_AMOUNT;
		},
		minimumText() {
			if (!this.cartMinimumType) {
				return '';
			}

			return this.cartMinimumType.value === CART_CONDITION_TYPES.QUANTITY ? 'Minimum item quantity' : 'Minimum purchase amount';
		},
	},
	methods: {
		open() {
			this.isShow = true;
			this.resetState();
		},
		close() {
			this.isShow = false;
		},
		resetState() {
			// Assign ID into tier values, Use ID on validation rule
			this.localList = this.defaultData.length
				? cloneDeep(this.defaultData).map((data, index) => ({ ...data, id: index }))
				: [
					{ ...cloneDeep(this.defaultTierValue), id: 0 },
					{ ...cloneDeep(this.defaultTierValue), id: 1 },
				]; // initial with 2 objects

			this.$v.$reset();
		},
		minimumValueErrors($vItem) {
			if (!$vItem.required) {
				return this.$t('global.error.required');
			}

			if (!$vItem.greaterThanPreviousTier) {
				return 'Please enter higher value';
			}

			return null;
		},
		handleConfirm() {
			this.isShow = false;
			this.$emit('onConfirm', this.localList);
		},
		handleAddNewValue() {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				// Assign ID into tier value, Use ID on validation rule
				this.localList.push({ ...cloneDeep(this.defaultTierValue), id: (this.localList.length) });
			}
		},
		handleRemoveValue(index) {
			if (index >= 0) {
				this.$delete(this.localList, index);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.btn-delete {
	.icon-trash {
		color: $color-black-25;
		width: rem(18);
		height: rem(18);
	}
}
</style>
