<template>
	<div class="form-container">
		<CRow>
			<CCol md="12">
				<h2 class="typo-h4 position-relative">
					e-Coupon setting
				</h2>
			</CCol>
		</CRow>
		<!-- End General info -->

		<!-- Usage limit -->
		<CRow class="form-group mt-4">
			<CCol md="12">
				<h2 class="typo-h4">
					Usage limit
				</h2>
			</CCol>
			<CCol md="12">
				<label>Usage limit per coupon</label>
			</CCol>
			<CCol md="6" class="mt-2">
				<BaseInputNumber
					v-model="$v.formData.usageLimit.$model"
					:is-valid="!$v.formData.usageLimit.$error"
					:invalid-feedback="$t('global.error.minimumNumber', { number: 1 })"
					:disabled="isDisabledUsageLimit"
					text-align="left"
					placeholder="default is 1 per coupon"
				/>
			</CCol>
		</CRow>
		<!-- End Usage limit -->

		<CRow class="my-4">
			<CCol md="8">
				<h2 class="typo-h4 position-relative">
					Create e-Coupon code
				</h2>
			</CCol>
			<CCol md="4" class="text-right">
				<CInputRadioGroup
					:options="couponModeOptions"
					:custom="true"
					:inline="true"
					:checked="formData.couponMode"
					size="sm"
					class="radio-group"
					name="create-coupon-type"
					@update:checked="handleChangeCouponMode"
				/>
			</CCol>
		</CRow>

		<CRow>
			<CCol>
				<component
					:is="ecouponMode"
					:data-e-coupon="formData"
					:is-edit-mode="isEditMode"
					@update:ecoupon="handleChangeECouponData"
				/>
			</CCol>
		</CRow>

		<CRow>
			<CCol md="12">
				<div class="mt-4">
					<CButton
						:disabled="!isEditMode || isExporting"
						class="transparent"
						color="secondary"
						data-test-id="export-coupon-code"
						@click="exportCouponCode"
					>
						{{ isExporting? 'Exporting...' : 'Export' }}
					</CButton>
					<span v-if="!isEditMode" class="typo-body-2 ml-2">Code can be exported after saves</span>
				</div>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { required, minValue } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';

import PromotionECouponManual from '@/components/PromotionECouponManual.vue';
import PromotionECouponAuto from '@/components/PromotionECouponAuto.vue';

import { exportECouponCodeAPI } from '../services/api/export.api';

import {
	PERIOD_STATUSES,
	COUPON_MODES,
	COUPON_MODE_OPTIONS,
} from '../enums/promotions';

export default {
	name: 'PromotionStepECoupon',

	components: {
		PromotionECouponManual,
		PromotionECouponAuto,
	},

	validations() {
		let schema = {};
		const base = {
			couponCodes: {
				required,
			},
			quantity: {
				required,
				minValue: minValue(1),
			},
			prefix: {},
			suffix: {},
			length: {},
			usageLimit: {
				minValue: minValue(1),
			},
		};

		schema = {
			formData: {
				...base,
			},
		};

		return schema;
	},

	mixins: [validationMixin],

	props: {
		defaultData: {
			type: Object,
			default: null,
		},
		isEditMode: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		const {
			id,
			couponMode = COUPON_MODE_OPTIONS[0].value,
			couponCodes = [],
			newCouponCodes = [],
			quantity = null,
			prefix = null,
			suffix = null,
			length = null,
			usageLimit = null,
		} = this.defaultData || {};

		return {
			isExporting: false,
			formData: {
				id,
				couponMode,
				couponCodes,
				newCouponCodes,
				quantity,
				prefix,
				suffix,
				length,
				usageLimit,
			},
		};
	},

	computed: {
		...mapState('promotions', {
			edit: 'edit',
		}),
		ecouponMode() {
			return this.isModeManual ? PromotionECouponManual : PromotionECouponAuto;
		},
		isModeManual() {
			return this.formData?.couponMode === COUPON_MODES.MANUAL;
		},
		isModeAuto() {
			return this.formData?.couponMode === COUPON_MODES.AUTO;
		},
		isMultiCode() {
			return this.formData?.couponCodes.length > 1 || this.formData?.newCouponCodes.length > 1;
		},
		isPeriodStatusExpired() {
			return this.periodStatus === PERIOD_STATUSES.EXPIRED;
		},
		isDisabledUsageLimit() {
			if (!this.isEditMode) {
				return false;
			} else if (this.isPeriodStatusExpired) {
				return true;
			}
			return this.isModeAuto || this.isMultiCode;
		},
		periodStatus() {
			const { period_status: periodStatus } = this.edit?.data || {};
			return periodStatus;
		},
		couponModeOptions() {
			return COUPON_MODE_OPTIONS.map((option) => ({
				...option,
				...(this.isEditMode ? { props: { disabled: true } } : null), // Disabled option if edit mode.
			}));
		},
	},

	watch: {
		formData: {
			deep: true,
			handler(data) {
				this.$emit(
					'update:is-valid',
					{
						valid: !this.$v.$invalid,
						data,
					},
				);
			},
		},
	},

	methods: {
		handleChangeCouponMode(value) {
			this.formData.couponMode = value;
			this.formData.couponCodes = [];
			this.formData.quantity = null;
			this.formData.prefix = null;
			this.formData.suffix = null;
			this.formData.length = null;
		},

		handleChangeECouponData(couponData) {
			const {
				couponCodes = [],
				newCouponCodes = [],
				quantity = null,
				prefix = null,
				suffix = null,
				length = null,
			} = couponData;
			this.formData.couponCodes = [...couponCodes];
			this.formData.newCouponCodes = [...newCouponCodes]; // for edit mode, API accept only new updated codes
			this.formData.quantity = quantity;
			this.formData.prefix = prefix;
			this.formData.suffix = suffix;
			this.formData.length = length;
		},

		async exportCouponCode() {
			this.isExporting = true;
			try {
				await exportECouponCodeAPI(this.formData.id);
				this.isExporting = false;
			} catch (e) {
				this.isExporting = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.form-container {
		margin-top: rem(80);

		.title-caption {
			position: absolute;
			right: 0;
			bottom: rem(4);
			font-weight: $font-weight-semi-bold;

			span {
				font-weight: $font-weight-bold;
			}
		}
	}
</style>
