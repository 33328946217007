<template>
	<CRow class="mb-3">
		<CCol md="7">
			<SKUListItem
				:thumbnail="thumbnail"
				:name="name"
				:sku="sku"
			/>
		</CCol>
		<CCol md="2">
			<div class="stock-qty text-right">
				{{ stockQty | numberFormat }}
			</div>
		</CCol>
		<CCol md="2">
			<BaseInputNumber
				v-model="localRewardQty"
				:is-valid="!$v.localRewardQty.$error"
				:invalid-feedback="$t('global.error.required')"
				append-text="Qty."
				text-align="left"
				placeholder="Reward quantity"
				@input="handleRewardQtyChange"
			/>
		</CCol>
		<CCol
			v-if="$listeners.onRemove"
			md="1"
		>
			<CButton
				class="transparent"
				data-test-id="button-remove"
				@click="handleRemove"
			>
				<CIcon name="cil-trash" class="color-black-45" />
			</CButton>
		</CCol>
	</CRow>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, minValue } from 'vuelidate/lib/validators';
import SKUListItem from '@/components/SKUListItem.vue';
import { IMAGE_PLACEHOLDER_URL } from '../enums/general';

export default {
	name: 'SKUWithStockListItem',
	components: {
		SKUListItem,
	},
	validations() {
		return {
			localRewardQty: {
				required,
				minValue: minValue(1),
			},
		};
	},
	mixins: [validationMixin],
	props: {
		id: {
			type: Number,
			default: null,
		},
		thumbnail: {
			type: String,
			default: IMAGE_PLACEHOLDER_URL,
		},
		name: {
			type: String,
			default: null,
		},
		sku: {
			type: String,
			default: null,
		},
		stockQty: {
			type: Number,
			default: 0,
		},
		rewardQty: {
			type: [String, Number],
			default: null,
		},
	},
	data() {
		return {
			localRewardQty: this.rewardQty,
		};
	},
	methods: {
		handleRemove() {
			this.$emit('onRemove');
		},
		handleRewardQtyChange(value) {
			this.$v.$touch();
			this.$emit(
				'update:rewardQty',
				{
					id: this.id,
					valid: !this.$v.$invalid,
					value,
				},
			);
		},
	},
};
</script>

<style lang="scss" scoped>
	.stock-qty {
		height: rem(35);
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
</style>
