<template>
	<div>
		<CModal
			:show.sync="isShow"
			:close-on-backdrop="false"
			centered
			size="lg"
		>
			<template #header-wrapper>
				<ModalHeaderWrapper title="Select e-Coupon" @onClose="close" />
			</template>
			<template #default>
				<CRow>
					<CCol>
						<FormGetCoupon
							ref="form-coupon"
							:coupon-promotion-list="list"
							@onGetCoupon="handleGetCoupon"
						>
							<template #no-item>
								<div
									class="no-item d-flex text-center"
									data-test-id="no-item"
								>
									<div class="d-block">
										<div
											class="no-sku font-weight-bolder color-black-45"
										>
											No e-Coupon yet
										</div>
										<div class="typo-body-2 color-black-45">
											Add "e-Coupon" in order to set as reward for this promotion.
										</div>
									</div>
								</div>
							</template>
							<template #list>
								<div class="item-list">
									<CRow class="mb-3">
										<CCol md="7">
											<CRow>
												<CCol md="2" class="label">
													ID
												</CCol>
												<CCol md="10" class="label">
													Promotion title
												</CCol>
											</CRow>
										</CCol>
										<CCol md="5">
											<CRow>
												<CCol md="4" class="label">
													Status
												</CCol>
												<CCol md="4" class="label">
													Period
												</CCol>
												<CCol md="4" class="label" />
											</CRow>
										</CCol>
									</CRow>
									<template v-for="item in formList">
										<CouponListItem
											v-if="item.id"
											:id="item.id"
											:key="item.id"
											:name="item.name"
											:coupon-codes-text="item.couponCodesText"
											:status="item.status"
											:period-status="item.periodStatus"
											:start-at="item.startAt"
											:end-at="item.endAt"
											@onRemove="handleRemoveCoupon(item.id)"
										/>
									</template>
								</div>
							</template>
						</FormGetCoupon>
					</CCol>
				</CRow>
			</template>
			<template #footer>
				<BaseModalFooter
					@onCancel="handleCancel"
					@onConfirm="handleSubmit"
				/>
			</template>
		</CModal>
	</div>
</template>

<script>
import BaseModalFooter from '@/components/BaseModalFooter.vue';
import ModalHeaderWrapper from '@/components/ModalHeaderWrapper.vue';
import FormGetCoupon from '@/components/FormGetCoupon.vue';
import CouponListItem from '@/components/CouponListItem.vue';

export default {
	name: 'ModalCoupon',
	components: {
		ModalHeaderWrapper,
		FormGetCoupon,
		CouponListItem,
		BaseModalFooter,
	},
	props: {
		coupons: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			isShow: false,
			list: [],
		};
	},
	computed: {
		formList() {
			return this.list && this.list.length ?
				this.list.map((item) => ({
					...item,
					valid: true,
				})) : null;
		},
	},
	methods: {
		resetState() {
			this.list = [...this.coupons];
			this.$refs['form-coupon'].resetState();
		},
		open() {
			this.isShow = true;
			this.resetState();
		},
		close() {
			this.isShow = false;
		},
		handleGetCoupon(coupons) {
			this.list = [...coupons];
		},
		handleRemoveCoupon(id) {
			const itemIndex = this.list.findIndex((item) => item.id === id);
			if (itemIndex >= 0) {
				this.list.splice(itemIndex, 1);
			}
			this.$refs['form-coupon'].resetState();
		},
		handleCancel() {
			this.close();
		},
		handleSubmit() {
			this.$emit('update:coupons', this.list);
			this.close();
		},
	},
};
</script>

<style lang="scss" scoped>
	.no-item {
		min-height: rem(200);
		justify-content: center;
		align-items: center;
	}

	.item-list {
		min-height: rem(200);
	}

	::v-deep .input-group-text {
		padding-right: rem(12);
	}
</style>
