<template>
	<div>
		<h2 class="typo-h4 mb-3 d-flex justify-content-between">
			Bundle setting*
			<CButton
				type="button"
				class="btn btn-add float-right"
				color="secondary"
				@click="handleAdd"
			>
				{{ list.length ? 'Edit' : 'Add' }} bundle setting
			</CButton>
		</h2>
		<IdListText v-if="list.length" :list="list" />
		<BaseNoItemBanner v-else text="There is no bundle setting added." />

		<ModalIdList
			ref="modal-list"
			:list="list"
			title="Select bundle setting"
			input-placeholder="Paste or type bundle setting id here"
			list-title="Bundle setting"
			no-data-title="No bundle setting yet"
			no-data-description="Add bundle setting in order to set condition."
			@onConfirm="handleChangeList"
		/>
	</div>
</template>

<script>
import IdListText from '@/components/IdListText.vue';
import ModalIdList from '@/components/ModalIdList.vue';

export default {
	name: 'PromotionProductBundleSetting',
	components: {
		IdListText,
		ModalIdList,
	},
	props: {
		list: {
			type: Array,
			default: () => [],
		},
		title: {
			type: String,
			default: null,
		},
		description: {
			type: String,
			default: null,
		},
	},
	methods: {
		handleAdd() {
			this.$refs['modal-list'].open();
		},
		handleChangeList(list = []) {
			this.$emit('onUpdate', list);
		},
	},
};
</script>
