<template>
	<div class="id-list-text">
		<p class="typo-body-2 color-black-45">{{ listText }}</p>
	</div>
</template>

<script>
export default {
	name: 'IdListText',
	props: {
		list: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		listText() {
			return this.list.join('; ');
		},
	},
};
</script>

<style lang="scss" scoped>
	.id-list-text {
		padding: rem(20) rem(16) 0;
		max-height: rem(320);
		overflow: auto;
		border: solid 1px $color-gray-400;
		border-radius: rem(4);
	}
</style>
