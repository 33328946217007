<template>
	<div class="form-container">
		<!-- Bundle setting -->
		<CRow v-if="isTypeBundle2">
			<CCol md="12">
				<PromotionProductBundleSetting
					:list="formData.bundleSettingIds"
					@onUpdate="handleBundleSettingIdsChange"
				/>
			</CCol>
			<CCol md="12" class="my-4">
				<hr>
			</CCol>
		</CRow>
		<!-- End Bundle condition -->

		<!-- Bundle condition -->
		<CRow v-if="isTypeBundle">
			<CCol md="12">
				<PromotionProductBundleCondition
					:products="formData.productBundleCondition"
					@onUpdate="handleProductBundleConditionChange"
				/>
			</CCol>
		</CRow>
		<!-- End Bundle condition -->
		<!-- Product condition -->
		<CRow v-else>
			<CCol md="12">
				<ProductCondition
					:default-data="formData.productCondition"
					@onUpdate="handleProductConditionChange"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol md="12" class="my-4">
				<hr>
			</CCol>
		</CRow>
		<!-- End Product condition -->

		<!-- Tier condition -->
		<CRow v-if="isTypeTier">
			<CCol md="12">
				<PromotionTierCondition
					:default-data="formData.tierCondition"
					:data-purchase-subtract-discount="formData.purchaseSubtractDiscountCondition"
					@onUpdate="handleTierConditionChange"
				/>
			</CCol>
		</CRow>
		<!-- End Tier condition -->

		<!-- Cart condition -->
		<CRow v-else>
			<CCol md="12">
				<PromotionCartCondition
					:default-data="formData.cartCondition"
					:data-purchase-subtract-discount="formData.purchaseSubtractDiscountCondition"
					@onUpdate:cartCondition="handleCartConditionChange"
					@onUpdate:purchaseSubtractDiscountCondition="handlePurchaseSubtractDiscountConditionChange"
				/>
			</CCol>
		</CRow>
		<!-- End Cart condition -->

		<CRow>
			<CCol md="12" class="my-4">
				<hr>
			</CCol>
		</CRow>

		<!-- Shipping condition -->
		<CRow>
			<CCol md="12">
				<PromotionShippingCondition
					:shipping-methods="formData.shippingMethods"
					@update:shippingMethods="handleShippingMethodChange"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol md="12" class="my-4">
				<hr>
			</CCol>
		</CRow>
		<!-- End Shipping condition -->

		<!-- Payment condition -->
		<CRow>
			<CCol md="12">
				<PromotionPaymentCondition
					:payment-methods="formData.paymentMethods"
					@update:paymentMethods="handlePaymentMethodChange"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol md="12" class="my-4">
				<hr>
			</CCol>
		</CRow>
		<!-- End Payment condition -->

		<!-- Customer condition -->
		<CRow>
			<CCol md="12">
				<PromotionCustomerCondition
					:default-data="formData.customerCondition"
					@onUpdate="handleCustomerConditionChange"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol md="12" class="my-4">
				<hr>
			</CCol>
		</CRow>
		<!-- End Customer condition -->

		<!-- Purchase History condition -->
		<CRow>
			<CCol md="12">
				<PromotionPurchaseHistoryCondition
					:default-data="formData.purchaseHistoryCondition"
					@onUpdate="handlePurchaseHistoryConditionChange"
				/>
			</CCol>
		</CRow>
		<!-- End Purchase History condition -->
	</div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import PromotionProductBundleSetting from '@/components/PromotionProductBundleSetting.vue';
import PromotionProductBundleCondition from '@/components/PromotionProductBundleCondition.vue';
import ProductCondition from '@/components/ProductCondition.vue';
import PromotionCartCondition from '@/components/PromotionCartCondition.vue';
import PromotionTierCondition from '@/components/PromotionTierCondition.vue';
import PromotionShippingCondition from '@/components/PromotionShippingCondition.vue';
import PromotionPaymentCondition from '@/components/PromotionPaymentCondition.vue';
import PromotionCustomerCondition from '@/components/PromotionCustomerCondition.vue';
import PromotionPurchaseHistoryCondition from '@/components/PromotionPurchaseHistoryCondition.vue';
import { PROMOTION_TYPES, CONDITION_TYPE_OPTIONS, CONDITION_TYPES, PURCHASE_SUBTRACT_DISCOUNT_OPTIONS } from '../enums/promotions';

export default {
	name: 'PromotionStepCondition',

	components: {
		PromotionProductBundleSetting,
		PromotionProductBundleCondition,
		ProductCondition,
		PromotionCartCondition,
		PromotionTierCondition,
		PromotionShippingCondition,
		PromotionPaymentCondition,
		PromotionCustomerCondition,
		PromotionPurchaseHistoryCondition,
	},

	validations() {
		const schema = {
			isTierConditionValid: {
				valid: (value) => !this.isTypeTier || value === true,
			},
			isProductBundleConditionValid: {
				valid: (value) => !this.isTypeBundle || value === true,
			},
			isCartConditionValid: {
				valid: (value) => value === true,
			},
			isCustomerConditionValid: {
				valid: (value) => value === true,
			},
			isPurchaseHistoryConditionValid: {
				valid: (value) => value === true,
			},
		};

		if (this.isTypeBundle2) {
			return {
				...schema,
				formData: {
					bundleSettingIds: {
						required,

					},
				},
			};
		}

		return schema;
	},


	mixins: [validationMixin],

	props: {
		defaultData: {
			type: Object,
			default: null,
		},
		promotionType: {
			type: String,
			default: PROMOTION_TYPES.GENERAL,
		},
	},
	data() {
		const minimumBundleAmount = 2;
		const minimumTierAmount = 2;
		const {
			bundleSettingIds = [],
			productCondition = null,
			productBundleCondition = [],
			cartCondition = null,
			tierCondition = [],
			purchaseSubtractDiscountCondition = PURCHASE_SUBTRACT_DISCOUNT_OPTIONS[0],
			shippingMethods = [],
			paymentMethods = [],
			customerCondition = null,
			purchaseHistoryCondition = null,
		} = this.defaultData || {};

		const isProductBundleConditionValid = !this.promotionType === PROMOTION_TYPES.BUNDLE || productBundleCondition.length >= minimumBundleAmount;
		const isTierConditionValid = !this.promotionType === PROMOTION_TYPES.TIER || tierCondition.length >= minimumTierAmount;

		return {
			CONDITION_TYPES,
			CONDITION_TYPE_OPTIONS,
			isCartConditionValid: true,
			isCustomerConditionValid: true,
			isPurchaseHistoryConditionValid: true,
			isProductBundleConditionValid,
			isTierConditionValid,
			minimumBundleAmount,
			minimumTierAmount,
			formData: {
				bundleSettingIds,
				productCondition,
				productBundleCondition,
				cartCondition,
				tierCondition,
				purchaseSubtractDiscountCondition,
				shippingMethods,
				paymentMethods,
				customerCondition,
				purchaseHistoryCondition,
			},
		};
	},

	computed: {
		isTypeTier() {
			return this.promotionType === PROMOTION_TYPES.TIER;
		},
		isTypeBundle() {
			return this.promotionType === PROMOTION_TYPES.BUNDLE;
		},
		isTypeBundle2() {
			return this.promotionType === PROMOTION_TYPES.BUNDLE_GROUP;
		},
	},

	watch: {
		formData: {
			deep: true,
			handler(data) {
				this.$emit(
					'update:is-valid',
					{
						valid: !this.$v.$invalid,
						data,
					},
				);
			},
		},
	},

	created() {
		// Trigger validation state at start up
		this.$emit(
			'update:is-valid',
			{
				valid: !this.$v.$invalid,
				data: this.formData,
			},
		);
	},

	methods: {
		handleBundleSettingIdsChange(list) {
			this.formData.bundleSettingIds = list.map((item) => Number(item));
		},
		handleProductConditionChange(condition) {
			this.formData.productCondition = condition;
		},
		handleProductBundleConditionChange(condition) {
			this.formData.productBundleCondition = condition;
			this.isProductBundleConditionValid = Array.isArray(condition) && condition.length >= this.minimumBundleAmount;
		},
		handleCartConditionChange(condition) {
			const { valid, data } = condition;
			this.isCartConditionValid = valid;
			this.formData.cartCondition = data || {}; // {} is use for trigger watching method
		},
		handleTierConditionChange(condition) {
			const { purchaseSubtractDiscount, cartCondition, tier } = condition;
			this.formData.cartCondition = cartCondition || {}; // {} is use for trigger watching method
			this.formData.purchaseSubtractDiscountCondition = PURCHASE_SUBTRACT_DISCOUNT_OPTIONS.find((option) => option.value === purchaseSubtractDiscount) || PURCHASE_SUBTRACT_DISCOUNT_OPTIONS[0];
			this.formData.tierCondition = tier;
			this.isTierConditionValid = Array.isArray(tier) && tier.length >= this.minimumTierAmount;
		},
		handlePurchaseSubtractDiscountConditionChange(option) {
			this.formData.purchaseSubtractDiscountCondition = option;
		},
		handleShippingMethodChange(list) {
			this.formData.shippingMethods = list;
		},
		handlePaymentMethodChange(list) {
			this.formData.paymentMethods = list;
		},
		handleCustomerConditionChange(condition) {
			const { valid, data } = condition;
			this.isCustomerConditionValid = valid;
			this.formData.customerCondition = data || {}; // {} is use for trigger watching method
		},
		handlePurchaseHistoryConditionChange(condition) {
			const { valid, data } = condition;
			this.isPurchaseHistoryConditionValid = valid;
			this.formData.purchaseHistoryCondition = data || {}; // {} is use for trigger watching method
		},
	},
};
</script>

<style lang="scss" scoped>
	.form-container {
		margin-top: rem(80);
	}
</style>

