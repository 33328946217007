<template>
	<div>
		<CRow>
			<CCol md="12">
				<h2 class="typo-h4 position-relative">
					Tier condition
				</h2>
			</CCol>
		</CRow>

		<CRow class="mt-3">
			<CCol md="8">
				<label>Cart minimum type</label>
			</CCol>
			<CCol md="4" class="float-right">
				<BaseDropDown
					:value="selectedCartMinimumType"
					:options="CART_MININUM_TYPE_OPTIONS"
					:searchable="false"
					:allow-empty="false"
					label="label"
					track-by="value"
					@input="handleChangeCartMinimumType"
				/>
			</CCol>
		</CRow>

		<CRow class="mt-2">
			<CCol md="8">
				<label>Calculate purchase amount from</label>
			</CCol>
			<CCol md="4" class="float-right">
				<BaseDropDown
					:value="selectedCalculatePurchaseAmount"
					:options="PURCHASE_SUBTRACT_DISCOUNT_OPTIONS"
					:searchable="false"
					:allow-empty="false"
					label="label"
					track-by="value"
					@input="handleChangePurchaseSubtractDiscount"
				/>
			</CCol>
		</CRow>

		<CRow class="mt-2">
			<CCol md="12">
				<div class="d-block">
					<label class="mb-0">Tier value</label>
					<CButton
						type="button"
						class="btn btn-add float-right"
						color="secondary"
						data-test-id="button-add"
						@click="$refs['modal-tier-value'].open()"
					>
						{{ `${tierValueList.length ? 'Edit' : 'Add'} tier value` }}
					</CButton>
				</div>
			</CCol>
		</CRow>

		<BaseNoItemBanner
			v-if="!tierValueList || tierValueList.length <= 0"
			text="There is tier value added."
			class="mt-3"
		/>
		<CRow v-else class="mt-3">
			<CCol lg="12">
				<CRow>
					<CCol md="2">
						<span class="label pl-0">Tier</span>
					</CCol>
					<CCol md="4" class="text-right">
						<span class="label">{{ minimumText }}</span>
					</CCol>
					<CCol md="3" class="text-right">
						<span class="label">Usage limit per tier</span>
					</CCol>
					<CCol md="3" class="text-right">
						<span class="label">Usage limit per user</span>
					</CCol>
				</CRow>
				<ul class="list-unstyled p-0 mt-2">
					<li class="border rounded-sm border-secondary pt-3">
						<CRow
							v-for="(value, index) in tierConditionList"
							:key="index"
							class="typo-body-2 d-flex px-3 mb-2 text-decoration-none"
						>
							<CCol md="2">
								{{ parseInt(index) + 1 }}
							</CCol>
							<CCol md="4" class="text-right">
								{{ value.minimumPurchaseAmount }}
							</CCol>
							<CCol md="3" class="text-right">
								{{ value.usageLimitPerTier }}
							</CCol>
							<CCol md="3" class="text-right">
								{{ value.usageLimitPerUser }}
							</CCol>
						</CRow>
					</li>
				</ul>
			</CCol>
		</CRow>

		<ModalTierValue
			ref="modal-tier-value"
			:default-data="tierValueList"
			:is-edit="tierValueList.length > 0"
			:cart-minimum-type="selectedCartMinimumType"
			@onConfirm="handleChangeValue"
		/>
	</div>
</template>

<script>
import ModalTierValue from '@/components/ModalTierValue.vue';
import {
	CART_CONDITION_TYPES,
	CART_MININUM_TYPE_OPTIONS,
	PURCHASE_SUBTRACT_DISCOUNT_OPTIONS,
} from '../enums/promotions';

import { transformedTierCartConditions } from '../assets/js/transform/promotions';

import { numberFormat } from '../assets/js/helpers';

export default {
	name: 'PromotionTierCondition',
	components: {
		ModalTierValue,
	},
	props: {
		defaultData: {
			type: Array,
			default: () => [],
		},
		dataPurchaseSubtractDiscount: {
			type: Object,
			default: null,
		},
	},
	data() {
		let selectedCartMinimumType = CART_MININUM_TYPE_OPTIONS[0]; // Default is 'Item quantity'
		// All cart minimum type of Tier will be the same each other.
		// So we can use the first to be value of selected cart minimum type.
		if (Array.isArray(this.defaultData) && this.defaultData.length > 0) {
			selectedCartMinimumType = CART_MININUM_TYPE_OPTIONS.find((option) => option.value === this.defaultData[0].cartMinimumType);
		}

		return {
			CART_MININUM_TYPE_OPTIONS,
			PURCHASE_SUBTRACT_DISCOUNT_OPTIONS,
			selectedCartMinimumType,
			selectedCalculatePurchaseAmount: this.dataPurchaseSubtractDiscount || PURCHASE_SUBTRACT_DISCOUNT_OPTIONS[0], // Default is 'Price before discount',

			tierValueList: [...this.defaultData],
		};
	},
	computed: {
		tierConditionList() {
			return this.tierValueList.map((tierValue) => {
				return {
					minimumPurchaseAmount: numberFormat(tierValue.minimumPurchaseAmount),
					usageLimitPerTier: tierValue.usageLimitPerTier || 'Unlimited usage',
					usageLimitPerUser: tierValue.usageLimitPerUser || 'Unlimited usage',
				};
			});
		},
		minimumText() {
			if (!this.selectedCartMinimumType) {
				return '';
			}

			return this.selectedCartMinimumType.value === CART_CONDITION_TYPES.QUANTITY ? 'Minimum item quantity' : 'Minimum purchase amount';
		},
	},
	methods: {
		handleChangeCartMinimumType(value) {
			this.selectedCartMinimumType = value;
			this.handleUpdateValue();
		},
		handleChangePurchaseSubtractDiscount(value) {
			this.selectedCalculatePurchaseAmount = value;
			this.handleUpdateValue();
		},
		handleChangeValue(value) {
			this.tierValueList = value;
			this.handleUpdateValue();
		},
		handleUpdateValue() {
			const cartCondition = transformedTierCartConditions({ cartMinimumType: this.selectedCartMinimumType?.value, tier: this.tierValueList }) || {}; // {} is use for trigger watching method

			const data = {
				cartCondition,
				purchaseSubtractDiscount: this.selectedCalculatePurchaseAmount?.value,
				tier: this.tierValueList.map((tier) => ({
					...tier,
					cartMinimumType: this.selectedCartMinimumType.value,
				})),
			};
			this.$emit('onUpdate', data);
		},
	},
};
</script>
