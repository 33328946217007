<template>
	<CRow class="mt-3 mb-4">
		<CCol>
			<div class="d-flex mb-3">
				<label class="mb-0 mr-5">
					<h6 data-test-id="title">
						e-Coupon*
					</h6>
					<span
						class="typo-caption color-black-45"
						data-test-id="description"
					>
						Set one or more e-Coupons this promotion. For email template, find or create an email template from Sendgrid and paste template ID here. Leave it blank to use default template from the system.
					</span>
				</label>
				<CButton
					type="button"
					class="btn btn-add align-self-start text-nowrap"
					color="secondary"
					data-test-id="button-add"
					@click="$refs['modal-coupon'].open()"
				>
					{{ `${coupons.length ? 'Edit' : 'Add'} e-Coupon` }}
				</CButton>
			</div>
			<CRow>
				<CCol v-if="!coupons.length" class="mb-3" lg="12">
					<BaseNoItemBanner
						:class="{ 'is-invalid': !isValid }"
						text="There is no e-Coupon added."
					/>
					<div v-if="invalidFeedback" class="invalid-feedback">
						{{ invalidFeedback }}
					</div>
				</CCol>
				<CCol v-else lg="12">
					<CRow class="mb-2 pl-3">
						<CCol md="7">
							<CRow>
								<CCol md="2" class="label">
									ID
								</CCol>
								<CCol md="10" class="label">
									Promotion title
								</CCol>
							</CRow>
						</CCol>
						<CCol md="5">
							<CRow>
								<CCol md="4" class="label p-0 pr-1">
									Status
								</CCol>
								<CCol md="4" class="label p-0 pr-1">
									Period
								</CCol>
								<CCol md="4" class="label px-0" />
							</CRow>
						</CCol>
					</CRow>
					<ul class="list-unstyled p-0">
						<li
							v-for="(item, index) in coupons"
							:key="index"
							class="border rounded-sm border-secondary pt-3 px-3 mb-3"
						>
							<CouponListItem
								:id="item.id"
								:name="item.name"
								:coupon-codes-text="item.couponCodesText"
								:status="item.status"
								:period-status="item.periodStatus"
								:start-at="item.startAt"
								:end-at="item.endAt"
								:email-template-id="item.emailTemplateId"
								show-email-template
								@update:emailTemplateId="handleEmailTemplateIdChange($event, index)"
							/>
						</li>
					</ul>
				</CCol>
			</CRow>
		</CCol>

		<ModalCoupon
			ref="modal-coupon"
			:coupons="coupons"
			@update:coupons="handleCouponListChange"
		/>
	</CRow>
</template>

<script>
import ModalCoupon from '@/components/ModalCoupon.vue';
import CouponListItem from '@/components/CouponListItem.vue';

export default {
	name: 'PromotionDiscountCoupon',
	components: {
		ModalCoupon,
		CouponListItem,
	},
	props: {
		coupons: {
			type: Array,
			default: () => [],
		},
		invalidFeedback: {
			type: String,
			default: null,
		},
		isValid: {
			type: Boolean,
			default: true,
		},
	},
	methods: {
		handleCouponListChange(list) {
			this.$emit('update:coupons', list);
		},
		handleEmailTemplateIdChange(value, index) {
			this.$set(this.coupons[index], 'emailTemplateId', value);
		},
	},
};
</script>
