<template>
	<div>
		<h6 class="cart-title">
			<FontAwesomeIcon class="tag-icon mr-2" :icon="['far', 'tag']" />
			<span>
				Not reach condition state
			</span>
		</h6>

		<CRow>
			<CCol md="12">
				<CInput
					v-model.trim="$v.formData.cartNotReachEN.$model"
					:is-valid="!$v.formData.cartNotReachEN.$error && null"
					:invalid-feedback="$t('global.error.required')"
					placeholder="e.g. 5% Discount with minimum spend of ฿6,900"
					description="Recommend 60 characters"
					label="Promotion description (EN)*"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol md="12">
				<CInput
					v-model.trim="$v.formData.cartNotReachTH.$model"
					:is-valid="!$v.formData.cartNotReachTH.$error && null"
					:invalid-feedback="$t('global.error.required')"
					placeholder="e.g. ส่วนลด 5% เมื่อซื้อครบ 6,900 บาท"
					description="Recommend 60 characters"
					label="Promotion description (TH)*"
				/>
			</CCol>
		</CRow>

		<h6 class="cart-title mt-4">
			<FontAwesomeIcon
				class="check-circle-icon mr-2"
				:icon="['fas', 'check-circle']"
			/>
			<span>
				Reach condition state
			</span>
		</h6>

		<CRow>
			<CCol md="12">
				<CInput
					v-model.trim="$v.formData.cartReachEN.$model"
					:is-valid="!$v.formData.cartReachEN.$error && null"
					:invalid-feedback="$t('global.error.required')"
					placeholder="e.g. Enjoy 5% off"
					description="Recommend 60 characters"
					label="Promotion description (EN)*"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol md="12">
				<CInput
					v-model.trim="$v.formData.cartReachTH.$model"
					:is-valid="!$v.formData.cartReachTH.$error && null"
					:invalid-feedback="$t('global.error.required')"
					placeholder="e.g. คุณได้รับส่วนลด 5%"
					description="Recommend 60 characters"
					label="Promotion description (TH)*"
				/>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
	name: 'PromotionDisplayingCart',
	validations: {
		formData: {
			cartNotReachEN: {
				required,
			},
			cartNotReachTH: {
				required,
			},
			cartReachEN: {
				required,
			},
			cartReachTH: {
				required,
			},
		},
	},
	mixins: [validationMixin],
	props: {
		defaultData: {
			type: Object,
			default: null,
		},
		isVisible: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		const { cartNotReachEN, cartNotReachTH, cartReachEN, cartReachTH } = this.defaultData || {};
		return {
			formData: {
				cartNotReachEN,
				cartNotReachTH,
				cartReachEN,
				cartReachTH,
			},
		};
	},
	watch: {
		isVisible: {
			handler(value) {
				this.handleDefaultValueOfCart(value);
			},
		},
		formData: {
			deep: true,
			handler(data) {
				this.$emit('update:is-valid', {
					valid: !this.$v.$invalid,
					data,
				});
			},
		},
	},
	methods: {
		handleDefaultValueOfCart(isVisible) {
			this.$v.formData.cartNotReachEN.$model = this.toggleDefaultValueOfCart(this.$v.formData.cartNotReachEN.$model, isVisible);
			this.$v.formData.cartNotReachTH.$model = this.toggleDefaultValueOfCart(this.$v.formData.cartNotReachTH.$model, isVisible);
			this.$v.formData.cartReachEN.$model = this.toggleDefaultValueOfCart(this.$v.formData.cartReachEN.$model, isVisible);
			this.$v.formData.cartReachTH.$model = this.toggleDefaultValueOfCart(this.$v.formData.cartReachTH.$model, isVisible);

			this.$v.formData.$reset();
		},
		toggleDefaultValueOfCart(value, isVisible) {
			if (!isVisible) {
				return value || '-';
			}

			return value === '-' ? '' : value;
		},
	},
};
</script>

<style lang="scss" scoped>
.cart-title {
	display: flex;
	align-items: center;

	.tag25-icon {
		color: $color-black-25;
	}

	.check-circle-icon {
		color: $color-success;
	}
}
</style>
