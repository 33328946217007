<template>
	<div class="form-container">
		<!-- PRODUCT DETAIL -->
		<CRow>
			<CCol lg="12">
				<h4 class="mb-4">
					Product detail
				</h4>
			</CCol>
		</CRow>

		<StoreFrontVisibility
			id="product-detail-visibility"
			:is-visible="formData.isProductDetailVisible"
			label="Product detail"
			image-url="/assets/images/promotion-step-4-product-detail.svg"
			image-alt="Promotion product detail"
			description="This location is a detail page of product that customer use to inspect and buy the product"
			@onChecked="handleProductDetailVisible"
		/>

		<template v-if="isTypeBundle">
			<CRow>
				<CCol md="12">
					<CInput
						v-model.trim="$v.formData.bundleNameEN.$model"
						:is-valid="!$v.formData.bundleNameEN.$error && null"
						:invalid-feedback="$t('global.error.required')"
						placeholder="e.g. Set gaming combo "
						description="Recommend 60 characters"
						label="Bundle set name (EN)*"
					/>
				</CCol>
			</CRow>
			<CRow>
				<CCol md="12">
					<CInput
						v-model.trim="$v.formData.bundleNameTH.$model"
						:is-valid="!$v.formData.bundleNameTH.$error && null"
						:invalid-feedback="$t('global.error.required')"
						placeholder="e.g. เซ็ตเกมมิ่งคอมโบ"
						description="Recommend 60 characters"
						label="Bundle set name (TH)*"
					/>
				</CCol>
			</CRow>
		</template>

		<CRow>
			<CCol md="12">
				<CInput
					v-model.trim="$v.formData.promotionNameEN.$model"
					:is-valid="!$v.formData.promotionNameEN.$error && null"
					:invalid-feedback="$t('global.error.required')"
					placeholder="e.g. 5% Discount"
					description="Recommend 60 characters"
					label="Promotion name (EN)*"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol md="12">
				<CInput
					v-model.trim="$v.formData.promotionNameTH.$model"
					:is-valid="!$v.formData.promotionNameTH.$error && null"
					:invalid-feedback="$t('global.error.required')"
					placeholder="e.g. ส่วนลด 5%"
					description="Recommend 60 characters"
					label="Promotion name (TH)*"
				/>
			</CCol>
		</CRow>

		<template v-if="isTypeECoupon">
			<CRow>
				<CCol md="12">
					<label>Coupon Name (EN)*</label>
					<BaseRichTextEditor
						v-model="$v.formData.couponTitleEN.$model"
						:is-valid="!$v.formData.couponTitleEN.$error"
						:invalid-feedback="$t('global.error.required')"
						placeholder="e.g. 5% Discount"
						description="Recommend 60 characters"
					/>
				</CCol>
			</CRow>
			<CRow>
				<CCol md="12">
					<label>Coupon Name (TH)*</label>
					<BaseRichTextEditor
						v-model="$v.formData.couponTitleTH.$model"
						:is-valid="!$v.formData.couponTitleTH.$error"
						:invalid-feedback="$t('global.error.required')"
						placeholder="e.g. ส่วนลด 5%"
						description="Recommend 60 characters"
					/>
				</CCol>
			</CRow>
		</template>

		<CRow>
			<CCol md="12">
				<label>Promotion description (EN)*</label>
				<BaseRichTextEditor
					v-model="$v.formData.promotionDescriptionEN.$model"
					:is-valid="!$v.formData.promotionDescriptionEN.$error"
					:invalid-feedback="$t('global.error.required')"
					placeholder="e.g. 5% discount with minimum spend of ฿6,900"
					description="Recommend 140 characters"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol md="12">
				<label>Promotion description (TH)*</label>
				<BaseRichTextEditor
					v-model="$v.formData.promotionDescriptionTH.$model"
					:is-valid="!$v.formData.promotionDescriptionTH.$error"
					:invalid-feedback="$t('global.error.required')"
					placeholder="e.g. ส่วนลด 5% เมื่อซื้อครบ 6,900 บาท"
					description="Recommend 140 characters"
				/>
			</CCol>
		</CRow>

		<CRow v-if="isTypeECoupon && formData.couponCodePattern">
			<CCol md="12">
				<div>
					<span class="typo-h6 mr-2">
						Promo code
					</span>
					<CouponBadge :label="formData.couponCodePattern" />
					<span class="typo-caption-2">{{ `If it’s multi-code, the code will be displayed as "${formData.couponCodePattern}" on storefront.` }}</span>
				</div>
			</CCol>
		</CRow>
		<!-- END OF PRODUCT DETAIL -->

		<hr>

		<template v-if="!isTypeBundle">
			<CRow>
				<CCol lg="12">
					<h4 class="mt-4 mb-4">
						Cart
					</h4>
				</CCol>
			</CRow>

			<StoreFrontVisibility
				id="cart-visibility"
				:is-visible="formData.isCartVisible"
				label="Entire order"
				image-url="/assets/images/promotion-step-4-cart.svg"
				image-alt="Promotion cart"
				description="This location is a cart page that customer use to inspect and buy the product"
				@onChecked="handleCartVisible"
			/>

			<CardDescriptionInfo
				class="cart-visibility-description mb-4"
				description="Please note that badge can be hidden even the visibility is on, due to customer's reached promotion limit or reward type."
			/>

			<template v-if="!isTypeTier">
				<PromotionDisplayingCart
					:default-data="formData"
					:is-visible="formData.isCartVisible"
					@update:is-valid="handleDisplayingValidChange($event)"
				/>
				<hr>
			</template>

			<template v-else-if="formData.tiers">
				<div
					v-for="(tier, index) in formData.tiers"
					:key="index"
					class="tier-condition-item mb-4"
				>
					<CRow>
						<CCol>
							<label>Tier {{ Number(index) + 1 }}</label>
						</CCol>
					</CRow>
					<hr>
					<PromotionDisplayingCart
						:default-data="tier"
						:is-visible="formData.isCartVisible"
						@update:is-valid="handleDisplayingValidChange($event, index)"
					/>
				</div>
			</template>
		</template>

		<template v-if="isTypeGeneral || isTypeECoupon">
			<CRow>
				<CCol md="12">
					<label>Term and condition (EN)*</label>
					<BaseRichTextEditor
						v-model="formData.termConditionEN"
						placeholder="-"
						description="Recommend 140 characters"
					/>
				</CCol>
			</CRow>
			<CRow>
				<CCol md="12">
					<label>Term and condition (TH)*</label>
					<BaseRichTextEditor
						v-model="formData.termConditionTH"
						placeholder="-"
						description="Recommend 140 characters"
					/>
				</CCol>
			</CRow>
		</template>
	</div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import StoreFrontVisibility from '@/components/StoreFrontVisibility.vue';
import PromotionDisplayingCart from '@/components/PromotionDisplayingCart.vue';
import CouponBadge from '@/components/CouponBadge.vue';
import CardDescriptionInfo from '@/components/CardDescriptionInfo.vue';

import { PROMOTION_TYPES } from '../enums/promotions';

import { cloneDeep } from '../assets/js/helpers';

export default {
	name: 'PromotionStepDisplaying',
	components: {
		StoreFrontVisibility,
		PromotionDisplayingCart,
		CouponBadge,
		CardDescriptionInfo,
	},
	validations() {
		const base = {
			promotionNameEN: {
				required,
			},
			promotionNameTH: {
				required,
			},
			promotionDescriptionEN: {
				required,
			},
			promotionDescriptionTH: {
				required,
			},
		};


		if (this.isTypeBundle) {
			return {
				formData: {
					...base,
					bundleNameEN: {
						required,
					},
					bundleNameTH: {
						required,
					},
				},
			};
		}

		if (this.isTypeECoupon) {
			return {
				formData: {
					...base,
					couponTitleEN: {
						required,
					},
					couponTitleTH: {
						required,
					},
				},
			};
		}

		if (this.isTypeTier) {
			return {
				formData: {
					...base,
					tiers: {
						required,
						$each: {
							cartNotReachEN: { required },
							cartNotReachTH: { required },
							cartReachEN: { required },
							cartReachTH: { required },
						},
					},
				},
			};
		}

		return {
			formData: {
				...base,
				cartNotReachEN: {
					required,
				},
				cartNotReachTH: {
					required,
				},
				cartReachEN: {
					required,
				},
				cartReachTH: {
					required,
				},
			},
		};
	},

	mixins: [validationMixin],
	props: {
		defaultData: {
			type: Object,
			default: null,
		},
		promotionType: {
			type: String,
			default: PROMOTION_TYPES.GENERAL,
		},
		isEditMode: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		const isTypeBundle = this.promotionType === PROMOTION_TYPES.BUNDLE;
		const {
			isProductDetailVisible = true,
			promotionNameEN = '',
			promotionNameTH = '',
			couponTitleTH = '',
			couponTitleEN = '',
			promotionDescriptionEN = '',
			promotionDescriptionTH = '',

			isCartVisible = !isTypeBundle, // If edit Type Bundle, default value is false.
			cartNotReachEN = '',
			cartNotReachTH = '',
			cartReachEN = '',
			cartReachTH = '',

			bundleNameEN = null,
			bundleNameTH = null,

			couponCodePattern = '',

			termConditionEN = '',
			termConditionTH = '',
		} = this.defaultData || {};
		return {
			formData: {
				isProductDetailVisible,
				promotionNameEN,
				promotionNameTH,
				couponTitleTH,
				couponTitleEN,
				promotionDescriptionEN,
				promotionDescriptionTH,
				isCartVisible,
				cartNotReachEN,
				cartNotReachTH,
				cartReachEN,
				cartReachTH,
				bundleNameEN,
				bundleNameTH,
				couponCodePattern,
				termConditionEN,
				termConditionTH,
			},
		};
	},
	computed: {
		isTypeTier() {
			return this.promotionType === PROMOTION_TYPES.TIER;
		},
		isTypeECoupon() {
			return this.promotionType === PROMOTION_TYPES.COUPON;
		},
		isTypeBundle() {
			return this.promotionType === PROMOTION_TYPES.BUNDLE;
		},
		isTypeGeneral() {
			return this.promotionType === PROMOTION_TYPES.GENERAL;
		},
	},
	watch: {
		defaultData: {
			deep: true,
			handler(data, prevData) {
				if (data !== prevData) {
					if (this.isTypeTier && data.tierCondition) {
						this.formData = {
							...this.formData,
							tiers: cloneDeep(this.getTiers(data)),
						};
					}

					if (this.isTypeECoupon) {
						const {
							couponQuantity = 0,
							couponCodePattern = '',
						} = data;

						// Trigger e-coupon data
						if (couponQuantity > 0) {
							// Trigger cart visibility logic only create new one
							// These three keys is not exists in create new mode
							if (!('isCartVisible' in data)
							&& !('cartReachEN' in data)
							&& !('cartReachTH' in data)) {
								this.$set(this.formData, 'isCartVisible', couponQuantity <= 1); // default as invisible at cart if multiple codes
							}

							this.$set(this.formData, 'couponCodePattern', couponCodePattern); // sample result is "BNNXXX"
						}
					}
				}
			},
		},
		formData: {
			deep: true,
			handler(data) {
				this.$emit('update:is-valid', {
					valid: !this.$v.$invalid,
					data,
				});
			},
		},
	},
	methods: {
		handleProductDetailVisible(value) {
			this.formData.isProductDetailVisible = value;
			this.handleDefaultValueOfProductDetail(value);
		},
		handleDefaultValueOfProductDetail(isVisible) {
			this.$v.formData.promotionNameEN.$model = this.toggleDefaultValueOfProductDetail(this.$v.formData.promotionNameEN.$model, isVisible);
			this.$v.formData.promotionNameTH.$model = this.toggleDefaultValueOfProductDetail(this.$v.formData.promotionNameTH.$model, isVisible);
			this.$v.formData.promotionDescriptionEN.$model = this.toggleDefaultValueOfProductDetail(this.$v.formData.promotionDescriptionEN.$model, isVisible);
			this.$v.formData.promotionDescriptionTH.$model = this.toggleDefaultValueOfProductDetail(this.$v.formData.promotionDescriptionTH.$model, isVisible);

			if (this.isTypeBundle) {
				this.$v.formData.bundleNameTH.$model = this.toggleDefaultValueOfProductDetail(this.$v.formData.bundleNameTH.$model, isVisible);
				this.$v.formData.bundleNameEN.$model = this.toggleDefaultValueOfProductDetail(this.$v.formData.bundleNameEN.$model, isVisible);
			}

			if (this.isTypeECoupon) {
				this.$v.formData.couponTitleTH.$model = this.toggleDefaultValueOfProductDetail(this.$v.formData.couponTitleTH.$model, isVisible);
				this.$v.formData.couponTitleEN.$model = this.toggleDefaultValueOfProductDetail(this.$v.formData.couponTitleEN.$model, isVisible);
			}

			this.$v.formData.$reset();
		},
		toggleDefaultValueOfProductDetail(value, isVisible) {
			if (!isVisible) {
				return value || '-';
			}

			return value === '-' ? '' : value;
		},
		handleCartVisible(value) {
			this.formData.isCartVisible = value;
		},
		getTiers(data) {
			if (!data) {
				return null;
			}

			return data.tierCondition.map((item, index) => {
				let currentTier = null;
				if (this.formData.tiers && index < this.formData.tiers.length) {
					currentTier = this.formData.tiers[index];
				}

				const cartNotReachEN = currentTier ? currentTier.cartNotReachEN : item.cartNotReachEN;
				const cartNotReachTH = currentTier ? currentTier.cartNotReachTH : item.cartNotReachTH;
				const cartReachEN = currentTier ? currentTier.cartReachEN : item.cartReachEN;
				const cartReachTH = currentTier ? currentTier.cartReachTH : item.cartReachTH;

				return {
					cartNotReachEN: cartNotReachEN || '',
					cartNotReachTH: cartNotReachTH || '',
					cartReachEN: cartReachEN || '',
					cartReachTH: cartReachTH || '',
					valid: currentTier?.valid || true,
				};
			});
		},
		handleDisplayingValidChange(value, index) {
			const { data: formData } = value;

			if (this.isTypeTier) {
				this.formData.tiers[index].cartNotReachEN = formData.cartNotReachEN;
				this.formData.tiers[index].cartNotReachTH = formData.cartNotReachTH;
				this.formData.tiers[index].cartReachEN = formData.cartReachEN;
				this.formData.tiers[index].cartReachTH = formData.cartReachTH;
			} else {
				this.formData.cartNotReachEN = formData.cartNotReachEN;
				this.formData.cartNotReachTH = formData.cartNotReachTH;
				this.formData.cartReachEN = formData.cartReachEN;
				this.formData.cartReachTH = formData.cartReachTH;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.form-container {
	margin-top: rem(80);

	.cart-title {
		display: flex;
		align-items: center;

		.tag25-icon {
			color: $color-black-25;
		}

		.check-circle-icon {
			color: $color-success;
		}
	}

	.cart-visibility-description {
		margin-top: -#{rem(8)};
	}

	.tier-condition {
		// .tier-condition-item
		&-item {
			background: $color-gray-100;
			border-radius: rem(4);
			padding: rem(16);
		}
	}
}
</style>
