/**
 * transformedActive
 * @param {Object} data
 * @param {Int|String} id
 * @returns {Object}
 */
export const transformedActive = (data = {}, id = null) => ({
	...data,
	isActive: id ? data.id === id : false,
});

/**
 * Transform list of object to list of id
 * @param {Array} list of object
 * @param {Array} list of id
 * @returns
 */
export const transformToListOfId = (list = []) => {
	if (Array.isArray(list) && list.length > 0) {
		return list.map((item) => item.id);
	}
	return [];
};
