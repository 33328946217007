<template>
	<div data-test-id="coupon-modal">
		<CForm class="d-flex align-items-start" @submit.prevent="handleSubmit">
			<CInput
				v-model.trim="inputPromotion"
				:is-valid="!isNotFound && !isDuplicated && isValid && null"
				:label="label"
				:placeholder="placeholder"
				class="flex-fill"
				add-input-classes="form-get-coupon-input"
				data-test-id="input-coupon"
				@input="handleChange"
			>
				<template #invalid-feedback>
					<div
						v-if="isNotFound"
						class="invalid-feedback"
						data-test-id="error-item-not-found"
					>
						We can't find this e-Coupon in the system
					</div>
					<div
						v-else-if="isDuplicated"
						class="invalid-feedback"
						data-test-id="error-item-duplicated"
					>
						This e-Coupon is already added, please try another.
					</div>
					<div
						v-else-if="!isValid"
						class="invalid-feedback"
						data-test-id="error-item-invalid"
					>
						{{ invalidFeedback }}
					</div>
				</template>
			</CInput>
			<CButton
				:disabled="isSubmitting"
				type="submit"
				class="btn-add ml-2"
				color="secondary"
				data-test-id="add-coupon-btn"
			>
				Add
			</CButton>
		</CForm>
		<slot
			v-if="!couponPromotionList.length"
			name="no-item"
		>
		</slot>
		<div
			v-else
			class="list-item"
			:class="{ 'show-border': hasBorder }"
		>
			<slot name="list"></slot>
		</div>
	</div>
</template>

<script>
import { getPromotionAPI } from '../services/api/promotions.api';
import { transformedPromotion } from '../assets/js/transform/promotions';
import { PROMOTION_TYPES } from '../enums/promotions';

export default {
	name: 'FormGetCoupon',
	props: {
		label: {
			type: String,
			default: null,
		},
		couponPromotionList: {
			type: Array,
			default: () => [],
		},
		hasBorder: {
			type: Boolean,
			default: false,
		},
		isValid: {
			type: Boolean,
			default: true,
		},
		invalidFeedback: {
			type: String,
			default: null,
		},
		placeholder: {
			type: String,
			default: 'Promotion ID',
		},
	},
	data() {
		return {
			isSubmitting: null,
			isNotFound: null,
			isDuplicated: null,
			inputPromotion: null,
		};
	},
	methods: {
		resetState() {
			this.inputPromotion = '';
			this.isNotFound = false;
			this.isDuplicated = false;
		},
		handleChange() {
			this.isNotFound = false;
			this.isDuplicated = false;
		},
		async handleSubmit() {
			const exists = this.couponPromotionList.find((promotion) => `${promotion.id}` === this.inputPromotion);
			if (exists) {
				this.isDuplicated = true;
				return;
			}

			this.isNotFound = false;
			this.isSubmitting = true;

			try {
				const { data } = await getPromotionAPI(this.inputPromotion);
				const promotion = transformedPromotion(data.data);
				if (promotion?.type?.value === PROMOTION_TYPES.COUPON) {
					this.$emit('onGetCoupon', [
						promotion,
						...this.couponPromotionList,
					]);
					this.resetState();
				} else {
					this.isNotFound = true;
				}
			} catch {
				this.isNotFound = true;
			} finally {
				this.isSubmitting = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	::v-deep .input-group-text {
		padding-right: 0;
	}

	::v-deep .form-get-sku-input {
		margin-right: rem(100);
	}

	.btn-add {
		min-width: rem(84);
	}

	.list-item {
		padding: rem(20) rem(16) 0;
		max-height: rem(320);
		overflow: auto;
	}

	.show-border {
		border: solid 1px $color-gray-400;
		border-radius: rem(4);
	}
</style>
