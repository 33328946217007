<template>
	<div>
		<CForm
			class="d-flex align-items-start mb-3"
			@submit.prevent="handleSubmit"
		>
			<div class="flex-fill">
				<CInput
					v-model.trim="inputSKU"
					class="form-sku-input"
					:disabled="disabled"
					:is-valid="!isNotFound && !isDuplicated && isValid && null"
					:label="label"
					:placeholder="placeholder"
					add-input-classes="form-get-sku-input"
					data-test-id="input-sku"
					@input="handleChange"
				>
					<template #invalid-feedback>
						<div
							v-if="isNotFound"
							class="invalid-feedback"
							data-test-id="error-item-not-found"
						>
							We can't find this SKU in the system
						</div>
						<div
							v-else-if="isDuplicated"
							class="invalid-feedback"
							data-test-id="error-item-duplicated"
						>
							This SKU is already added, please try another.
						</div>
						<div
							v-else-if="!isValid"
							class="invalid-feedback"
							data-test-id="error-item-invalid"
						>
							{{ invalidFeedback }}
						</div>
					</template>
				</CInput>
				<div v-if="description" class="typo-caption color-black-45 mb-2">
					{{ description }}
				</div>
			</div>
			<CButton
				:disabled="disabled || isSubmitting"
				type="submit"
				class="btn-add ml-2"
				color="secondary"
				data-test-id="add-sku-btn"
			>
				Add
			</CButton>
		</CForm>
		<slot
			v-if="!product"
			name="no-item"
		>
		</slot>
		<ul
			v-else
			class="list-item"
			:class="{ 'show-border': hasBorder }"
		>
			<SKUListItem
				:key="product.id"
				:thumbnail="product.thumbnail"
				:name="product.name"
				:sku="product.sku"
				@onRemove="handleRemove"
			/>
		</ul>
	</div>
</template>

<script>
import SKUListItem from '@/components/SKUListItem.vue';
import { getProductBySKUAPI } from '../services/api/sku.api';
import { transformedProduct } from '../assets/js/transform/products';

export default {
	name: 'FormGetSingleProductBySKU',
	components: {
		SKUListItem,
	},
	props: {
		label: {
			type: String,
			default: null,
		},
		product: {
			type: Object,
			default: null,
		},
		hasBorder: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		isValid: {
			type: Boolean,
			default: true,
		},
		invalidFeedback: {
			type: String,
			default: null,
		},
		placeholder: {
			type: String,
			default: 'SKU number',
		},
		description: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			isSubmitting: false,
			isNotFound: false,
			isDuplicated: false,
			inputSKU: '',
		};
	},
	methods: {
		resetState() {
			this.inputSKU = '';
			this.isNotFound = false;
			this.isDuplicated = false;
		},
		handleChange() {
			this.isNotFound = false;
			this.isDuplicated = false;
		},
		handleRemove() {
			this.resetState();
			this.$emit('onRemove', null);
		},
		handleReset() {
			this.resetState();
			this.$emit('update:product', this.product);
			this.close();
		},
		async handleSubmit() {
			const exists = this.product ? this.product.sku.toUpperCase() === this.inputSKU.toUpperCase() : null;
			if (exists) {
				this.isDuplicated = true;
				return;
			}

			this.isNotFound = false;
			this.isSubmitting = true;

			try {
				const params = {
					q: this.inputSKU,
				};
				const { data } = await getProductBySKUAPI(params);
				this.$emit('onGetProduct', transformedProduct(data.data));
				this.resetState();
			} catch {
				this.isNotFound = true;
			} finally {
				this.isSubmitting = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	::v-deep .input-group-text {
		padding-right: 0;
	}

	::v-deep .form-get-sku-input {
		margin-right: rem(100);
	}

	.form-sku-input {
		margin-bottom: rem(8);
	}

	.btn-add {
		min-width: rem(84);
	}

	.list-item {
		padding: rem(20) rem(16) 0 rem(16);
		max-height: rem(320);
		overflow-x: hidden;
		overflow-y: auto;
	}

	.show-border {
		border: solid 1px $color-gray-400;
		border-radius: rem(4);
	}
</style>
