<template>
	<div>
		<label>
			Auto create e-Coupon
		</label>
		<CRow class="mb-4">
			<CCol md="12">
				<div class="coupon-setting py-4 px-3">
					<CRow>
						<CCol md="6" class="pt-4">
							<label class="pt-3">Code format</label>
						</CCol>
						<CCol md="3">
							<CInput
								v-model.trim="$v.formData.prefix.$model"
								:is-valid="!$v.formData.prefix.$error && null"
								:invalid-feedback="$t('global.error.required')"
								:disabled="isEditMode"
								label="Prefix"
							/>
						</CCol>
						<CCol md="3">
							<BaseDropDown
								v-model="formData.suffix"
								:options="SUFFIX_CODE_FORMAT_OPTIONS"
								:searchable="false"
								:allow-empty="false"
								:disabled="isEditMode"
								class="select-custom"
								label="label"
								track-by="value"
								label-drop-down="Suffix"
							/>
						</CCol>
					</CRow>
					<CRow>
						<CCol md="6">
							<label>Code length</label>
						</CCol>
						<CCol md="6">
							<BaseInputNumber
								v-model="$v.formData.length.$model"
								:is-valid="!$v.formData.length.$error"
								:disabled="isEditMode"
								invalid-feedback="Length must be greater than 0"
								class="form-group"
							/>
						</CCol>
					</CRow>
					<CRow>
						<CCol md="6">
							<label>Coupon quantity</label>
							<p class="typo-caption-2">Maximum quantity is {{ MAXIMUM_COUPON_QUANTITY | numberFormat }}. This is also depends on code length.</p>
						</CCol>
						<CCol md="6">
							<BaseInputNumber
								v-model="$v.formData.quantity.$model"
								:is-valid="!$v.formData.quantity.$error"
								:invalid-feedback="quatityErrors"
								placeholder="Qty."
								class="form-group"
							/>
						</CCol>
					</CRow>
					<CRow>
						<CCol md="6">
							<span v-if="isEditMode" class="typo-caption-2 color-black-90">
								To generate more coupon codes, please update coupon quantity.
							</span>
						</CCol>
						<CCol md="6" class="text-right">
							<CButton
								:disabled="!enableGenerating || isGenerating"
								class="transparent"
								color="secondary"
								data-test-id="generate-coupon-code"
								@click="generateCouponCode"
							>
								{{ isGenerating ? "Generating..." : "Generate" }}
							</CButton>
						</CCol>
					</CRow>
				</div>
			</CCol>
		</CRow>
		<template v-if="!isGenerating">
			<CRow v-if="!showCouponList">
				<CCol>
					<BaseNoItemBanner text="There is no auto code generated." />
				</CCol>
			</CRow>
			<CRow
				v-else
				class="mt-4"
			>
				<CCol md="12">
					<p class="typo-body-2 mt-2 mb-2">
						Total {{ totalCouponCodes }} code(s)
					</p>
				</CCol>
				<CCol md="12">
					<div class="coupon-codes p-3">
						<p class="typo-caption-2 mb-0">{{ couponCodesText }}</p>
					</div>
				</CCol>
				<CCol v-if="couponCodePattern" md="12" class="mt-3">
					<div>
						<CouponBadge :label="couponCodePattern" />
						<span class="typo-caption-2">{{ `If it’s multi-code, the code will be displayed as "${couponCodePattern}" on storefront.` }}</span>
					</div>
				</CCol>
			</CRow>
		</template>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import { required, minValue, maxValue } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';

import CouponBadge from '@/components/CouponBadge.vue';

import { SUFFIX_CODE_FORMAT_OPTIONS, MAXIMUM_COUPON_QUANTITY } from '../enums/promotions';

import { getGenerateCodesAPI } from '../services/api/promotions.api';

import { getECouponCodePattern, numberFormat } from '../assets/js/helpers';

export default {
	name: 'PromotionECouponAuto',

	validations() {
		const minQuantity = this.isEditMode ? Number(this.dataECoupon.quantity || 1) : 1;
		const schema = {
			formData: {
				prefix: {
					required,
				},
				suffix: {
					required,
				},
				length: {
					required,
					minValue: minValue(1),
				},
				quantity: {
					required,
					minValue: minValue(minQuantity),
					maxValue: maxValue(MAXIMUM_COUPON_QUANTITY),
				},
			},
		};

		return schema;
	},

	components: {
		CouponBadge,
	},

	mixins: [validationMixin],

	props: {
		dataECoupon: {
			type: Object,
			default: null,
		},
		isEditMode: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		const {
			prefix = '',
			suffix = null,
			length = null,
			quantity = null,
			couponCodes = [],
		} = this.dataECoupon || {};

		return {
			SUFFIX_CODE_FORMAT_OPTIONS,
			MAXIMUM_COUPON_QUANTITY,
			isGenerating: false,
			formData: {
				prefix,
				suffix: SUFFIX_CODE_FORMAT_OPTIONS.find((option) => option.value === suffix) || SUFFIX_CODE_FORMAT_OPTIONS[0],
				length,
				quantity,
				couponCodes,
				newCouponCodes: [],
			},
		};
	},

	computed: {
		mergeCouponCodes() {
			if (Array.isArray(this.formData.couponCodes) && Array.isArray(this.formData.newCouponCodes)) {
				return [...new Set([...this.formData.couponCodes, ...this.formData.newCouponCodes])];
			}

			return [];
		},
		couponCodesText() {
			if (!this.formData.couponCodes || !this.formData.couponCodes.length) {
				return '';
			}

			// Fully summary
			if (this.mergeCouponCodes.length > 0) {
				let valueText = '';
				const maximumValueList = 5;

				if (this.formData.couponCodes.length > maximumValueList) {
					// Get at most 5 items
					valueText = `${this.mergeCouponCodes.slice(0, maximumValueList).join(', ')} and ${(this.mergeCouponCodes.length - maximumValueList)} more codes`;
				} else {
					valueText = this.mergeCouponCodes.join(', ');
				}

				return valueText;
			}

			return '';
		},
		couponCodePattern() {
			if (!this.formData?.prefix || !this.formData?.length) {
				return null;
			}

			if (this.mergeCouponCodes?.length <= 1) {
				return null;
			}

			return getECouponCodePattern(this.formData.prefix, this.formData.length, this.mergeCouponCodes); // sample result is "BNNXXX"
		},
		quatityErrors() {
			if (!this.$v.formData.quantity.required) {
				return this.$t('global.error.required');
			}

			if (!this.$v.formData.quantity.minValue) {
				return 'Must be higher than current quantity.';
			}

			if (!this.$v.formData.quantity.maxValue) {
				return `Must be less than ${numberFormat(MAXIMUM_COUPON_QUANTITY)}.`;
			}

			return null;
		},
		showCouponList() {
			return this.mergeCouponCodes.length > 0;
		},
		totalCouponCodes() {
			return this.mergeCouponCodes.length;
		},
		enableGenerating() {
			if (!this.isEditMode) {
				return !this.$v.$invalid;
			}
			// If edit mode, can generate if new quantity greater than old
			return Number(this.dataECoupon.quantity) < Number(this.formData.quantity);
		},
	},

	watch: {
		dataECoupon: {
			deep: true,
			handler(data) {
				// Watch props data change and update this
				this.formData.newCouponCodes = data.newCouponCodes || [];
				this.formData.couponCodes = data.couponCodes || [];
				this.formData.quantity = data.quantity;
			},
		},
	},

	methods: {
		...mapActions({
			showToast: 'toast/showToast',
		}),
		handleChangeCouponMode(value) {
			this.formData.couponMode = value;
		},
		async generateCouponCode() {
			this.isGenerating = true;
			if (!this.isEditMode) {
				this.formData.couponCodes = [];
			}

			const { prefix, suffix, length, quantity } = this.formData || {};
			const totalQuantity = this.isEditMode ? (Number(quantity) - Number(this.dataECoupon.quantity)) : quantity;
			const params = {
				prefix,
				suffix: suffix.value,
				length,
				quantity: totalQuantity,
			};

			try {
				const res = await getGenerateCodesAPI(params);
				if (!this.isEditMode) {
					this.formData.couponCodes = [...res?.data?.data] || [];
				} else {
					this.formData.newCouponCodes = [...this.formData.newCouponCodes, ...res?.data?.data] || [];
				}
				this.showToast({
					content: 'Coupon codes successfully generated.',
					header: 'Success',
					type: 'success',
				});
			} catch (e) {
				this.showToast({
					content: 'Coupon codes generate failed',
					header: 'Failure',
					type: 'danger',
				});
			} finally {
				this.isGenerating = false;
				const couponCodes = this.isEditMode ? this.formData.newCouponCodes : this.formData.couponCodes;
				if (couponCodes && couponCodes.length) {
					const onlyNewCouponCodes = this.isEditMode ? this.formData.newCouponCodes.filter((code) => !this.formData.couponCodes.includes(code)) : [];
					const data = {
						prefix: this.formData.prefix,
						suffix: this.formData.suffix.value,
						quantity: this.formData.quantity,
						length: this.formData.length,
						couponCodes: this.mergeCouponCodes, // Need to keep current pattern
						newCouponCodes: onlyNewCouponCodes,
					};
					this.$emit('update:ecoupon', data);
				}
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.coupon-setting {
	background: $color-gray-100;
	border-radius: rem(4);
	padding: rem(16);
}

.coupon-codes {
	border-radius: rem(4);
	border: rem(1) solid $color-gray-400;
	background: $color-white;
}
</style>
