<template>
	<CRow class="mb-4">
		<CCol md="3">
			<img
				:src="imageUrl"
				:alt="imageAlt"
				data-test-id="image"
			>
		</CCol>
		<CCol md="6">
			<label data-test-id="label">{{ label }}</label>
			<div
				class="description"
				data-test-id="description"
			>
				<span class="typo-body-2">{{ description }}</span>
			</div>
		</CCol>
		<CCol md="3">
			<div class="form-group form-group-switch">
				<CSwitch
					:id="id"
					:checked="isVisible"
					variant="3d"
					size="sm"
					class="switch-custom"
					color="success"
					data-test-id="switch"
					@update:checked="handleChecked"
				/>
				<span
					:for="id"
					data-test-id="switch-text"
				>
					{{ isVisible ? visibleLabels[0] : visibleLabels[1] }}
				</span>
			</div>
		</CCol>
		<CCol v-if="isAlternateProductUrl" md="9" class="offset-md-3">
			<CInput
				v-model.trim="$v.slug.$model"
				:is-valid="!$v.slug.$error && null"
				:invalid-feedback="$t('global.error.url')"
				:description="slugHelperText"
				label="Alternate product link"
				@input="handleAltLink"
			/>
		</CCol>
	</CRow>
</template>

<script>
import { url } from 'vuelidate/lib/validators';

export default {
	name: 'StoreFrontVisibility',
	validations: {
		slug: {
			url,
		},
	},
	props: {
		id: {
			type: String,
			required: true,
		},
		label: {
			type: String,
			default: null,
		},
		imageUrl: {
			type: String,
			required: true,
		},
		imageAlt: {
			type: String,
			default: null,
		},
		isVisible: {
			type: Boolean,
			default: false,
		},
		description: {
			type: String,
			default: null,
		},
		visibleLabels: {
			type: Array,
			default: () => ['Visible', 'Hidden'], // TODO: Remove this one after revise design, use same text for all
		},
		alternateProductUrl: {
			type: String,
			default: '',
		},
		isAlternateProductUrl: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			slug: this.alternateProductUrl,
		};
	},
	computed: {
		slugHelperText() {
			if (this.$v.slug.$error) {
				return '';
			}
			return this.$t('global.alternateProductUrl');
		},
	},
	methods: {
		handleChecked(checked) {
			this.$emit('onChecked', checked);
		},
		handleAltLink(link) {
			this.$emit('onInputLink', link);
		},
	},
};
</script>


<style lang="scss" scoped>
.description {
	line-height: 1;

	> span {
		color: $color-black-45;
	}
}

.form-group-switch {
	display: flex;

	.switch-custom {
		margin-right: rem(12);
	}

	> span {
		font-size: rem(14);
		color: $color-black-90;
	}
}
</style>
